@import './font-inter.less';

// A handful tool to generate this:
// https://google-webfonts-helper.herokuapp.com/fonts

/* allison-regular - latin */
@font-face {
  font-family: 'Allison';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/allison/allison-v1-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Allison'), url('/fonts/allison/allison-v1-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/allison/allison-v1-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/allison/allison-v1-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/allison/allison-v1-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/allison/allison-v1-latin-regular.svg#Allison') format('svg'); /* Legacy iOS */
}

/* anton-regular - latin */
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/anton/anton-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Anton'), url('/fonts/anton/anton-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/anton/anton-v15-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/anton/anton-v15-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/anton/anton-v15-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/anton/anton-v15-latin-regular.svg#Anton') format('svg'); /* Legacy iOS */
}

/* bangers-regular - latin */
@font-face {
  font-family: 'Bangers';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/bangers/bangers-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/bangers/bangers-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/bangers/bangers-v13-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/bangers/bangers-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/bangers/bangers-v13-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/bangers/bangers-v13-latin-regular.svg#Bangers') format('svg'); /* Legacy iOS */
}

/* barlow-condensed-regular - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/barlow-condensed/barlow-condensed-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Barlow Condensed'),
    url('/fonts/barlow-condensed/barlow-condensed-v5-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/barlow-condensed/barlow-condensed-v5-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/barlow-condensed/barlow-condensed-v5-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/barlow-condensed/barlow-condensed-v5-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/barlow-condensed/barlow-condensed-v5-latin-regular.svg#BarlowCondensed')
      format('svg'); /* Legacy iOS */
}

/* black-ops-one-regular - latin */
@font-face {
  font-family: 'Black Ops One';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/black-ops-one/black-ops-one-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Black Ops One'),
    url('/fonts/black-ops-one/black-ops-one-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/black-ops-one/black-ops-one-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/black-ops-one/black-ops-one-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/black-ops-one/black-ops-one-v12-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/black-ops-one/black-ops-one-v12-latin-regular.svg#BlackOpsOne') format('svg'); /* Legacy iOS */
}

/* boogaloo-regular - latin */
@font-face {
  font-family: 'Boogaloo';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/boogaloo/boogaloo-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Boogaloo'), url('/fonts/boogaloo/boogaloo-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/boogaloo/boogaloo-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/boogaloo/boogaloo-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/boogaloo/boogaloo-v12-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/boogaloo/boogaloo-v12-latin-regular.svg#Boogaloo') format('svg'); /* Legacy iOS */
}

/* bungee-shade-regular - latin */
@font-face {
  font-family: 'Bungee Shade';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/bungee-shade/bungee-shade-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Bungee Shade'),
    url('/fonts/bungee-shade/bungee-shade-v6-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/bungee-shade/bungee-shade-v6-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/bungee-shade/bungee-shade-v6-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/bungee-shade/bungee-shade-v6-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/bungee-shade/bungee-shade-v6-latin-regular.svg#BungeeShade') format('svg'); /* Legacy iOS */
}

/* cabin-sketch-regular - latin */
@font-face {
  font-family: 'Cabin Sketch';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/cabin-sketch/cabin-sketch-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Cabin Sketch'),
    url('/fonts/cabin-sketch/cabin-sketch-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/cabin-sketch/cabin-sketch-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/cabin-sketch/cabin-sketch-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/cabin-sketch/cabin-sketch-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/cabin-sketch/cabin-sketch-v14-latin-regular.svg#CabinSketch') format('svg'); /* Legacy iOS */
}

/* codystar-regular - latin */
@font-face {
  font-family: 'Codystar';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/codystar/codystar-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Codystar'), url('/fonts/codystar/codystar-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/codystar/codystar-v10-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/codystar/codystar-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/codystar/codystar-v10-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/codystar/codystar-v10-latin-regular.svg#Codystar') format('svg'); /* Legacy iOS */
}

/* creepster-regular - latin */
@font-face {
  font-family: 'Creepster';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/creepster/creepster-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Creepster'), url('/fonts/creepster/creepster-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/creepster/creepster-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/creepster/creepster-v9-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/creepster/creepster-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/creepster/creepster-v9-latin-regular.svg#Creepster') format('svg'); /* Legacy iOS */
}

/* dancing-script-regular - latin */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/dancing-script/dancing-script-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Dancing Script'),
    url('/fonts/dancing-script/dancing-script-v16-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/dancing-script/dancing-script-v16-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/dancing-script/dancing-script-v16-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/dancing-script/dancing-script-v16-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/dancing-script/dancing-script-v16-latin-regular.svg#DancingScript')
      format('svg'); /* Legacy iOS */
}

/* eater-regular - latin */
@font-face {
  font-family: 'Eater';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/eater/eater-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Eater'), url('/fonts/eater/eater-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/eater/eater-v11-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/eater/eater-v11-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/eater/eater-v11-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/eater/eater-v11-latin-regular.svg#Eater') format('svg'); /* Legacy iOS */
}

/* emilys-candy-regular - latin */
@font-face {
  font-family: 'Emilys Candy';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/emilys-candy/emilys-candy-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Emilys Candy'),
    url('/fonts/emilys-candy/emilys-candy-v8-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/emilys-candy/emilys-candy-v8-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/emilys-candy/emilys-candy-v8-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/emilys-candy/emilys-candy-v8-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/emilys-candy/emilys-candy-v8-latin-regular.svg#EmilysCandy') format('svg'); /* Legacy iOS */
}

/* finger-paint-regular - latin */
@font-face {
  font-family: 'Finger Paint';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/finger-paint/finger-paint-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Finger Paint'),
    url('/fonts/finger-paint/finger-paint-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/finger-paint/finger-paint-v10-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/finger-paint/finger-paint-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/finger-paint/finger-paint-v10-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/finger-paint/finger-paint-v10-latin-regular.svg#FingerPaint') format('svg'); /* Legacy iOS */
}

/* fredericka-the-great-regular - latin */
@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/fredericka-the-great/fredericka-the-great-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/fredericka-the-great/fredericka-the-great-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/fredericka-the-great/fredericka-the-great-v10-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/fredericka-the-great/fredericka-the-great-v10-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('/fonts/fredericka-the-great/fredericka-the-great-v10-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/fredericka-the-great/fredericka-the-great-v10-latin-regular.svg#FrederickatheGreat') format('svg'); /* Legacy iOS */
}

/* frijole-regular - latin */
@font-face {
  font-family: 'Frijole';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/frijole/frijole-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/frijole/frijole-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/frijole/frijole-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/frijole/frijole-v9-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/frijole/frijole-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/frijole/frijole-v9-latin-regular.svg#Frijole') format('svg'); /* Legacy iOS */
}

/* grenze-gotisch-regular - latin */
@font-face {
  font-family: 'Grenze Gotisch';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/grenze-gotisch/grenze-gotisch-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Grenze Gotisch'),
    url('/fonts/grenze-gotisch/grenze-gotisch-v3-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/grenze-gotisch/grenze-gotisch-v3-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/grenze-gotisch/grenze-gotisch-v3-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/grenze-gotisch/grenze-gotisch-v3-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/grenze-gotisch/grenze-gotisch-v3-latin-regular.svg#GrenzeGotisch')
      format('svg'); /* Legacy iOS */
}

/* hanalei-regular - latin */
@font-face {
  font-family: 'Hanalei';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/hanalei/hanalei-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Hanalei'), url('/fonts/hanalei/hanalei-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/hanalei/hanalei-v13-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/hanalei/hanalei-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/hanalei/hanalei-v13-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/hanalei/hanalei-v13-latin-regular.svg#Hanalei') format('svg'); /* Legacy iOS */
}

/* indie-flower-regular - latin */
@font-face {
  font-family: 'Indie Flower';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/indie-flower/indie-flower-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Indie Flower'),
    url('/fonts/indie-flower/indie-flower-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/indie-flower/indie-flower-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/indie-flower/indie-flower-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/indie-flower/indie-flower-v12-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/indie-flower/indie-flower-v12-latin-regular.svg#IndieFlower') format('svg'); /* Legacy iOS */
}

/* italianno-regular - latin */
@font-face {
  font-family: 'Italianno';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/italianno/italianno-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Italianno'), url('/fonts/italianno/italianno-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/italianno/italianno-v11-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/italianno/italianno-v11-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/italianno/italianno-v11-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/italianno/italianno-v11-latin-regular.svg#Italianno') format('svg'); /* Legacy iOS */
}

/* kaushan-script-regular - latin */
@font-face {
  font-family: 'Kaushan Script';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/kaushan-script/kaushan-script-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Kaushan Script'),
    url('/fonts/kaushan-script/kaushan-script-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/kaushan-script/kaushan-script-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/kaushan-script/kaushan-script-v9-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/kaushan-script/kaushan-script-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/kaushan-script/kaushan-script-v9-latin-regular.svg#KaushanScript')
      format('svg'); /* Legacy iOS */
}

/* kranky-regular - latin */
@font-face {
  font-family: 'Kranky';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/kranky/kranky-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Kranky'), url('/fonts/kranky/kranky-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/kranky/kranky-v13-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/kranky/kranky-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/kranky/kranky-v13-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/kranky/kranky-v13-latin-regular.svg#Kranky') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/lato/lato-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato'), url('/fonts/lato/lato-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/lato/lato-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/lato/lato-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/lato/lato-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/lato/lato-v20-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lobster-regular - latin */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/lobster/lobster-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lobster'), url('/fonts/lobster/lobster-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/lobster/lobster-v23-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/lobster/lobster-v23-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/lobster/lobster-v23-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/lobster/lobster-v23-latin-regular.svg#Lobster') format('svg'); /* Legacy iOS */
}

/* love-ya-like-a-sister-regular - latin */
@font-face {
  font-family: 'Love Ya Like A Sister';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/love-ya-like-a-sister/love-ya-like-a-sister-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Love Ya Like A Sister'),
    url('/fonts/love-ya-like-a-sister/love-ya-like-a-sister-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/love-ya-like-a-sister/love-ya-like-a-sister-v11-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/love-ya-like-a-sister/love-ya-like-a-sister-v11-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('/fonts/love-ya-like-a-sister/love-ya-like-a-sister-v11-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/love-ya-like-a-sister/love-ya-like-a-sister-v11-latin-regular.svg#LoveYaLikeASister') format('svg'); /* Legacy iOS */
}

/* luckiest-guy-regular - latin */
@font-face {
  font-family: 'Luckiest Guy';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/luckiest-guy/luckiest-guy-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Luckiest Guy'),
    url('/fonts/luckiest-guy/luckiest-guy-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/luckiest-guy/luckiest-guy-v11-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/luckiest-guy/luckiest-guy-v11-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/luckiest-guy/luckiest-guy-v11-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/luckiest-guy/luckiest-guy-v11-latin-regular.svg#LuckiestGuy') format('svg'); /* Legacy iOS */
}

/* merienda-regular - latin */
@font-face {
  font-family: 'Merienda';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/merienda/merienda-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Merienda'), url('/fonts/merienda/merienda-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/merienda/merienda-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/merienda/merienda-v9-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/merienda/merienda-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/merienda/merienda-v9-latin-regular.svg#Merienda') format('svg'); /* Legacy iOS */
}

/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/merriweather/merriweather-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/merriweather/merriweather-v25-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/merriweather/merriweather-v25-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/merriweather/merriweather-v25-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/merriweather/merriweather-v25-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/merriweather/merriweather-v25-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* monoton-regular - latin */
@font-face {
  font-family: 'Monoton';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/monoton/monoton-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Monoton'), url('/fonts/monoton/monoton-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/monoton/monoton-v10-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/monoton/monoton-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/monoton/monoton-v10-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/monoton/monoton-v10-latin-regular.svg#Monoton') format('svg'); /* Legacy iOS */
}

/* Nunito Black */

@font-face {
  font-family: 'Nunito';
  font-style: bold;
  font-weight: 900;
  src: url('/fonts/nunito/Nunito-Black.ttf') format('truetype');
}

/* open-sans-condensed-300 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/open-sans-condensed/open-sans-condensed-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Condensed'),
    url('/fonts/open-sans-condensed/open-sans-condensed-v15-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/open-sans-condensed/open-sans-condensed-v15-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/open-sans-condensed/open-sans-condensed-v15-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/open-sans-condensed/open-sans-condensed-v15-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/open-sans-condensed/open-sans-condensed-v15-latin-300.svg#OpenSansCondensed')
      format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/open-sans/open-sans-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans'), url('/fonts/open-sans/open-sans-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/open-sans/open-sans-v23-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/open-sans/open-sans-v23-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/open-sans/open-sans-v23-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/open-sans/open-sans-v23-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* orbitron-regular - latin */
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/orbitron/orbitron-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Orbitron'), url('/fonts/orbitron/orbitron-v17-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/orbitron/orbitron-v17-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/orbitron/orbitron-v17-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/orbitron/orbitron-v17-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/orbitron/orbitron-v17-latin-regular.svg#Orbitron') format('svg'); /* Legacy iOS */
}

/* permanent-marker-regular - latin */
@font-face {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/permanent-marker/permanent-marker-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Permanent Marker'),
    url('/fonts/permanent-marker/permanent-marker-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/permanent-marker/permanent-marker-v10-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/permanent-marker/permanent-marker-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/permanent-marker/permanent-marker-v10-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/permanent-marker/permanent-marker-v10-latin-regular.svg#PermanentMarker')
      format('svg'); /* Legacy iOS */
}

/* rampart-one-regular - latin */
@font-face {
  font-family: 'Rampart One';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/rampart-one/rampart-one-v1-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Rampart One'),
    url('/fonts/rampart-one/rampart-one-v1-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/rampart-one/rampart-one-v1-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/rampart-one/rampart-one-v1-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/rampart-one/rampart-one-v1-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/rampart-one/rampart-one-v1-latin-regular.svg#RampartOne') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/roboto/roboto-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), url('/fonts/roboto/roboto-v27-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/roboto/roboto-v27-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/roboto/roboto-v27-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/roboto/roboto-v27-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/roboto/roboto-v27-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* rye-regular - latin */
@font-face {
  font-family: 'Rye';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/rye/rye-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Rye'), url('/fonts/rye/rye-v8-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/rye/rye-v8-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/rye/rye-v8-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/rye/rye-v8-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/rye/rye-v8-latin-regular.svg#Rye') format('svg'); /* Legacy iOS */
}

/* source-code-pro-regular - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/source-code-pro/source-code-pro-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Code Pro'),
    url('/fonts/source-code-pro/source-code-pro-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/source-code-pro/source-code-pro-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/source-code-pro/source-code-pro-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/source-code-pro/source-code-pro-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/source-code-pro/source-code-pro-v14-latin-regular.svg#SourceCodePro')
      format('svg'); /* Legacy iOS */
}

/* space-grotesk-regular - latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/space-grotesk/space-grotesk-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Space Grotesk'),
    url('/fonts/space-grotesk/space-grotesk-v6-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/space-grotesk/space-grotesk-v6-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/space-grotesk/space-grotesk-v6-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/space-grotesk/space-grotesk-v6-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/space-grotesk/space-grotesk-v6-latin-regular.svg#SpaceGrotesk') format('svg'); /* Legacy iOS */
}

/* special-elite-regular - latin */
@font-face {
  font-family: 'Special Elite';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/special-elite/special-elite-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Special Elite'),
    url('/fonts/special-elite/special-elite-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/special-elite/special-elite-v11-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/special-elite/special-elite-v11-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/special-elite/special-elite-v11-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/special-elite/special-elite-v11-latin-regular.svg#SpecialElite')
      format('svg'); /* Legacy iOS */
}

/* teko-regular - latin */
@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/teko/teko-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Teko'), url('/fonts/teko/teko-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/teko/teko-v10-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/teko/teko-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/teko/teko-v10-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/teko/teko-v10-latin-regular.svg#Teko') format('svg'); /* Legacy iOS */
}

/* tourney-regular - latin */
@font-face {
  font-family: 'Tourney';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/tourney/tourney-v1-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Tourney'), url('/fonts/tourney/tourney-v1-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/tourney/tourney-v1-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/tourney/tourney-v1-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/tourney/tourney-v1-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/tourney/tourney-v1-latin-regular.svg#Tourney') format('svg'); /* Legacy iOS */
}

/* unifrakturmaguntia-regular - latin */
@font-face {
  font-family: 'UnifrakturMaguntia';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/unifrakturmaguntia/unifrakturmaguntia-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/unifrakturmaguntia/unifrakturmaguntia-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/unifrakturmaguntia/unifrakturmaguntia-v11-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/unifrakturmaguntia/unifrakturmaguntia-v11-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('/fonts/unifrakturmaguntia/unifrakturmaguntia-v11-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/unifrakturmaguntia/unifrakturmaguntia-v11-latin-regular.svg#UnifrakturMaguntia') format('svg'); /* Legacy iOS */
}

/* urbanist-regular - latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/urbanist/urbanist-v1-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Urbanist'), url('/fonts/urbanist/urbanist-v1-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/urbanist/urbanist-v1-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/urbanist/urbanist-v1-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/urbanist/urbanist-v1-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/urbanist/urbanist-v1-latin-regular.svg#Urbanist') format('svg'); /* Legacy iOS */
}

/* wallpoet-regular - latin */
@font-face {
  font-family: 'Wallpoet';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/wallpoet/wallpoet-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Wallpoet'), url('/fonts/wallpoet/wallpoet-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/wallpoet/wallpoet-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/wallpoet/wallpoet-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/wallpoet/wallpoet-v12-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/wallpoet/wallpoet-v12-latin-regular.svg#Wallpoet') format('svg'); /* Legacy iOS */
}

/* yesteryear-regular - latin */
@font-face {
  font-family: 'Yesteryear';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/yesteryear/yesteryear-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Yesteryear'), url('/fonts/yesteryear/yesteryear-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/yesteryear/yesteryear-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/yesteryear/yesteryear-v9-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/yesteryear/yesteryear-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/yesteryear/yesteryear-v9-latin-regular.svg#Yesteryear') format('svg'); /* Legacy iOS */
}
