@keyframes tip-fading-in {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
  }
}

@keyframes tip-fading-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100px);
  }
}

.room-loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: linear-gradient(42.98deg, #55017d 25.23%, #1901aa 100.55%);

  &.hidden {
    display: none;
  }

  img {
    margin-top: -23%;
    margin-right: -2%;
    width: 35%;
  }
}

.tips-container {
  margin-top: 10px;

  .tip-animation-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 500px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;

    &.starting {
      animation: tip-fading-in 0.5s ease-in-out;
    }

    &.finishing {
      animation: tip-fading-out 0.5s ease-in-out;
    }

    @media (max-width: 615px) {
      width: 100%;
    }
  }
}

@media (max-width: 875px) {
  .room-loading-container {
    img {
      width: 55%;
    }
  }

  .tips-container {
    .tip-animation-wrapper {
      padding: 0 10px;
      width: unset;
      font-size: 14px;
    }
  }
}

@media (max-width: 516px) {
  .room-loading-container {
    img {
      width: 100%;
    }
  }

  .tips-container {
    .tip-animation-wrapper {
      padding: 0 10px;
      width: unset;
      font-size: 14px;
    }
  }
}
