@import './variables.less';

.first-time-user-notification {
  display: block;
  flex-direction: row;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10006;
  width: 650px;
  height: 650px;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  color: black;

  h3 {
    margin-bottom: 10px;
  }

  .left-area {
    width: 80px;
    flex-shrink: 0;
    background-color: @primaryColor;
  }

  .message-title {
    padding-bottom: 18px;
    color: #101010;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
  }

  .message-text {
    color: @darkTextColor;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 0px !important;
  }

  .message {
    padding: 40px !important;
    font-size: 14px;

    .link {
      cursor: pointer;
      text-decoration: underline;
      color: #666;
    }
  }

  .next-button {
    margin-top: 50px !important;
  }

  .next-button-text {
    color: @primaryColor !important;
    margin: 0px;
    font-size: 1.2em;
  }

  .close-button {
    cursor: pointer;
    margin: auto;
    margin-top: 50px;
    width: 150px;
    text-align: center;
    display: none;
  }

  .close-button-end {
    cursor: pointer;
    margin: auto;
    margin-top: 40px;
    width: 155px;
    text-align: center;
    background-color: @primaryColor;
    border-radius: 8px;
    padding: 10px;
  }

  .close-button-text {
    color: @primaryColor !important;
    margin: 0px;
  }

  .close-button-text-end {
    color: white !important;
    font-weight: bold;
  }

  .slideshow-slide {
    padding-left: 10px;
    padding-right: 10px;
  }

  .uk-active a {
    background-color: @primaryColor;
  }

  .uk-slideshow-items {
    height: 400px;
  }

  .uk-slideshow {
    margin-top: 0px;
  }

  .uk-slidenav {
    // background-color: black;
  }

  video {
    // height: 300px;
  }
}

.first-time-user-background-cover {
  display: none;
  opacity: 0.7;
  background-color: black;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 10005;
}
