@import '../variables.less';
@import '../buttons.less';
@import '../theming.less';
@import '../mixins.less';

.sdk-element {
  .svg-color(currentcolor);

  .app-container {
    background: var(--primary-background, @menuBackground);
    border-radius: 10px;
    padding: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;

    // UI
    .container {
      display: flex;
      padding: 5px;
      flex: 1 1 auto;
      min-height: auto;
      .visible-on-hover {
        display: none;
      }

      :hover .visible-on-hover {
        display: block;
      }
    }

    .font-size-s {
      font-size: 12px;
    }

    .font-size-m {
      font-size: 16px;
    }

    .font-size-l {
      font-size: 23px;
    }

    .font-size-xl {
      font-size: 36px;
    }

    here-avatar {
      border-radius: 50%;
      height: 40px;
      width: 40px;
      margin: 0 3px;
      overflow: hidden;
    }

    div {
      color: var(--primary-foreground, #fff);
    }

    button {
      .here-button-regular;

      height: 50px;
      font-size: 1.2em;
      line-height: 5px;
      margin: 5px;

      .button-title {
        padding: 10px 20px;
        color: inherit;
      }
    }
    button.secondary {
      // Ghost or minimal buttons look weird on the midnight background. Primary and secondary
      // buttons don't co-exist for now in the SDK apps, so both of them will look like regular ones.
      // But one day we'll need to address this.
      .here-button-regular;
    }

    label span {
      color: #fff;
    }

    .clickable {
      cursor: pointer;
    }

    input {
      .themed-input;

      margin: 5px;
      height: 40px;
      vertical-align: middle;
      font-size: 1.2em;
    }

    input[type='checkbox'] {
      appearance: none;
    }

    input[type='checkbox'] {
      width: 20px;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 20px;
      border: 0.1px solid white;
      border-radius: 3px;
      outline: none;
    }

    input[type='checkbox']:checked {
      background: var(--secondary-background, @primaryColor);
      border: 0.1px solid #0000;
    }

    input[type='checkbox']:checked::after {
      margin-left: 5.3px;
      margin-top: -7.6px;
      width: 5px;
      height: 15px;
      border: solid var(--secondary-foreground, white);
      border-radius: 1px;
      border-width: 0 2.5px 2.5px 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      content: '';
      display: inline-block;
    }
  }

  // Developer stuff

  .errors {
    background-color: white;
    color: red;
    font-size: 0.8em;
    position: absolute;
    bottom: 0;
    width: calc(100% - 10px);
    padding: 5px;
    display: none;
  }
}
