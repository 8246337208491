@import './variables.less';

.tictactoe-card {
  h1,
  h3 {
    color: var(--primary-foreground, white);
    margin: 0;
  }

  h1 {
    font-size: 2.1em;
  }

  h3 {
    font-size: 1.3em;
  }

  .ttt-restart {
    height: 50px;
    padding: 10px 20px;
    font-size: 1.2em;
    line-height: 5px;
    float: right;
  }

  .game-title-screen-div {
    width: 100%;
    height: 100%;
    background: var(--primary-background, black);
    border-radius: 18px;
    overflow: hidden;
  }

  .ttt-container {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    position: relative;
    width: 100%;
    height: calc(100% - 5em);
  }

  .ttt-cell:hover {
    background: var(--primary-foreground-alpha-50, rgba(255, 255, 255, 0.5));
  }

  .ttt-status {
    line-height: 2em;
    height: 2em;
    font-size: 20px;
    color: var(--primary-foreground, white);
  }

  .ttt-left {
    border-right: 1px solid var(--primary-foreground, slategray);
  }

  .ttt-right {
    border-left: 1px solid var(--primary-foreground, slategray);
  }

  .ttt-top {
    border-bottom: 1px solid var(--primary-foreground, slategray);
  }

  .ttt-bottom {
    border-top: 1px solid var(--primary-foreground, slategray);
  }

  .ttt-cell {
    cursor: pointer;
    font-size: 30px;
    color: var(--primary-foreground, white);
    vertical-align: middle;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ttt-x {
    background-color: rgba(255, 128, 32, 0.5);
    vertical-align: middle;
  }

  .ttt-o {
    background-color: rgba(32, 32, 255, 0.5);
    vertical-align: middle;
  }

  .ttt-restart {
    height: 100%;
    padding: 14px;
    margin: 5px;
    font-size: 20px;
    border-radius: 5px;
  }

  .ttt-game {
    padding: 14px 20px;
    padding-left: 20px;
    height: calc(100% - 28px);
  }
}
