.camera-hand {
  position: absolute;
  z-index: 9997;
  pointer-events: none;
  height: 0;

  .hand-image {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
  }

  .camera-arm {
    position: absolute;
    width: 35%;
    margin-left: 32.5%;
    margin-top: 10px;
  }
}
