.electron-windows-titlebar {
  position: fixed;
  top: 0;
  height: 20px;
  width: 100%;
  z-index: 100000;
  display: flex;
  justify-content: flex-end;
  background-color: #12002d80;
  -webkit-app-region: drag; // this prop allows moving app by dragging titlebar

  &.hidden {
    display: none;
  }

  .electron-windows-buttons {
    margin-right: 5px;
    display: flex;
  }

  .electron-windows-button {
    border: none;
    background: inherit;
    opacity: 0.75;
    -webkit-app-region: no-drag; // forbid to move app by dragging titlebar buttons, allow to click titlebar buttons
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &:focus {
      outline: none;
    }
  }
}
