@import './codemirror.less';
@import './firepad.less';
@import './variables.less';

.uk-dropdown.feedback-window {
  width: 380px !important;
  height: fit-content;
  line-height: 1.6em;
  font-size: 0.85em;
  background: var(--primary-background, white);
}

.feedback-tabs {
  display: flex;
  align-items: baseline;
  overflow: hidden;
  border-bottom: 1px solid var(--primary-foreground-alpha-70, rgba(70, 70, 70, 0.7));
  background-color: transparent;
}

.feedback-tab-link {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 16px;
  margin-right: 14px;
  color: var(--primary-foreground-alpha-70, rgba(0, 0, 0, 0.7));

  &-active,
  &:hover {
    color: var(--primary-foreground, @primaryColor);
    font-weight: bold;
  }

  &-active {
    border-bottom: 1px solid var(--primary-foreground, @primaryColor);
  }
}

.feedback-link,
.feedback-link:hover {
  color: @primaryColor;
  text-decoration: underline;
}

.feedback-response {
  margin-top: 10%;
  margin-left: 3%;
  margin-right: 3%;
}

#feedback-whats-new-content {
  margin-top: 15px;
}

.feedback-badge-hi {
  position: relative;
  top: -18px;
  right: -36%;
}

.feedback-badge-new {
  position: relative;
  top: -18px;
  right: -53%;
}

.etc-tools-dropdown {
  .feedback-badge-new,
  .feedback-badge-hi {
    top: -7px;
  }
}

.update-title {
  font-weight: bold;
  font-size: 1.1em;
  color: black;
  width: 75%;
}

.uk-notification-message {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  max-width: 280px;

  .important-update-notification {
    .text-block {
      text-align: left;
      margin-top: 5px;

      .title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;

        h3 {
          font-weight: bolder;
          font-size: 1rem;
          margin-bottom: 0;
        }
        .update-date {
          font-size: 0.8rem;
          color: #aaa;
        }
      }

      .new-badge {
        position: relative;
        margin: 0;
        width: fit-content;
        display: block;
      }

      p {
        padding-top: 10px;
        color: #666666;
        font-size: 0.9rem;
        margin: 0 0 5px 0;

        span {
          display: block;
          text-align: justify;
        }
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .feedback-link {
      display: block;
      text-align: center;
    }
  }
}

.uk-notification.uk-notification-bottom-left {
  z-index: 10006; // bigger that z-index of the .room-menu-container
}
