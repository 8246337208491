@import './variables.less';
@import './mixins.less';

.common-button-styles() {
  &,
  &:focus,
  &:hover {
    outline: none;
  }

  border-radius: 10px;
  box-shadow: 0 4px 10px var(--secondary-foreground-alpha-10, rgba(0, 0, 0, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 5px var(--secondary-foreground-alpha-30, rgba(0, 0, 0, 0.3));
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.here-button-regular {
  .common-button-styles();
  .svg-color(var(--secondary-foreground, white));

  border: 2px solid transparent;
  color: var(--secondary-foreground, white);
  background: var(--secondary-background, @primaryColor);
}

.here-button-ghost {
  .common-button-styles();
  .svg-color(var(--secondary-background, @primaryColor));

  border: 2px solid var(--secondary-background, @primaryColor);
  color: var(--secondary-background, @primaryColor);
  background: transparent;
  box-shadow: none;

  &:hover {
    .svg-color(var(--secondary-foreground, white));

    color: var(--secondary-foreground, white);
  }
}

.here-button-regular,
.here-button-ghost {
  &:hover {
    background: var(--secondary-background-alpha-80, rgba(@primaryColorRgb, 0.8));
    border-color: var(--secondary-foreground-alpha-20, rgba(@primaryColorRgb, 0.2));
  }
}

.here-button-minimal {
  .common-button-styles();
  .svg-color(var(--primary-foreground, black));

  border: 1px solid var(--primary-foreground-alpha-20, rgba(0, 0, 0, 0.2));
  color: var(--primary-foreground, black);
  background: var(--primary-background-alpha-70, rgba(255, 255, 255, 0.7));

  &:hover {
    border: 1px solid var(--primary-foreground-alpha-40, rgba(0, 0, 0, 0.4));
  }
}

.here-button-danger {
  .common-button-styles();
  .svg-color(white);

  color: white;
  background: #f6335d;
  border: 1px solid rgba(18, 0, 45, 0.2);

  &:hover {
    background: rgba(246, 51, 93, 0.7);
  }
}

.here-button-secondary {
  .common-button-styles();
  .svg-color(black);

  box-shadow: none;
  border: 2px solid transparent;
  background: #ededed;

  &:hover {
    background: #f2f2f2;
  }
}

// TODO: Get rid of this in favor of themed buttons
.here-button {
  padding: 8px 6px;
  color: white;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  background-color: @primaryColor;

  &:focus {
    outline: none;
  }

  &.outlined {
    color: @primaryColor;
    border: 1px solid @primaryColor;
    background-color: transparent;

    &:hover {
      background-color: rgba(@primaryColor, 0.05);
    }
  }

  &.link {
    padding: 0;
    height: fit-content;
    width: fit-content;
    color: var(--primary-foreground, @primaryColor);
    border: none;
    background-color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}
