@import '../../../../styles/variables.less';
@import '../../../../styles/mixins.less';

.notification-section {
  .title {
    display: flex;
    justify-content: space-between;
    padding-top: 4px;

    h2 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: var(--primary-foreground, black);
    }
  }

  .subtitle {
    font-size: 0.9em;
    color: var(--primary-foreground, #868686);
    margin-top: 0;
  }

  .svg-color(var(--primary-foreground, @primaryColor));
}
