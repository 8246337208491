.chat-image-preview-modal-body {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-image-preview-image {
  max-height: 75vh;
}
