@dialog-border-radius: 10px;
@red: #f6335d;
@yellow: #f6a730;
@green: #39c360;

here-camera-lite-mode {
  display: none;
  hr,
  #camera-lite-mode-how-to-improve-things {
    display: none;
  }
}

.camera-lite-mode-modal {
  .uk-modal-dialog {
    padding: 3px;
    border-radius: @dialog-border-radius;
    color: #12002d;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
  }

  .uk-modal-header {
    padding: 0px;
    border-radius: @dialog-border-radius;
  }

  .uk-close {
    color: white;
  }

  .uk-modal-title {
    display: flex;
    padding: 0;
    font-weight: 700;
    line-height: 24px;
    font-size: 16px;

    img {
      display: inline-flex;
      padding: 15px 10px;
      margin: 0;
      width: 10%;
      border-radius: @dialog-border-radius 0 0 0;
    }
    span {
      display: inline-flex;
      padding: 20px;
      width: 90%;
      border-radius: 0 @dialog-border-radius 0 0;
      background-color: #f2f2f2;

      img {
        padding: 2px 5px 5px 5px;
      }
    }
  }

  h4 {
    font-weight: 700;
    line-height: 24px; /* 150% */
    padding-bottom: 15px;
    font-size: 16px;
  }

  ul {
    font-weight: 500;
    line-height: 26px; /* 162.5% */
    margin-left: 15px;
  }

  .uk-button-primary {
    color: #fff;
    background-color: #6b1be3;
    &:hover,
    &:focus,
    &:active {
      background-color: #711cf0;
    }
    border-radius: @dialog-border-radius;

    height: 40px;
    line-height: 20px;
    width: 40%;
    margin: 10px;
  }
}

#camera-lite-mode-poor-connection-modal {
  .uk-modal-body {
    padding: 30px 70px;
  }

  .uk-modal-title {
    border-radius: @dialog-border-radius @dialog-border-radius 0 0;
    color: #fff;
    img,
    span {
      background-color: @red;
    }
  }
}

#camera-lite-mode-ask-lower-bandwidth,
#camera-lite-mode-ask-turn-on-video,
#camera-lite-mode-ask-turn-off-video {
  .uk-modal-dialog {
    color: #12002d;
    padding: 10px;
  }

  .uk-modal-header {
    background-color: #f2f2f2;
  }

  .uk-modal-close-outside {
    border-radius: 50%;
    color: #12002d;
    background-color: #fff;
    padding: 10px;
    top: 25px;
    right: 25px;
  }

  .uk-modal-title {
    border-radius: @dialog-border-radius 0 0 @dialog-border-radius;
    img {
      border-radius: @dialog-border-radius 0 0 @dialog-border-radius;
    }
    span {
      background-color: #f2f2f2;
      border-radius: 0 0 @dialog-border-radius @dialog-border-radius;
    }
  }
}

#camera-lite-mode-ask-lower-bandwidth {
  .uk-modal-title > img {
    background-color: @yellow;
  }
}

#camera-lite-mode-ask-turn-off-video {
  .uk-modal-title > img {
    background-color: @red;
  }
}

#camera-lite-mode-ask-turn-on-video {
  .uk-modal-title > img {
    background-color: @green;
  }
}

#camera-lite-mode-ask-lower-bandwidth.details,
#camera-lite-mode-ask-turn-on-video.details,
#camera-lite-mode-ask-turn-off-video.details {
  .uk-modal-header {
    border-radius: 0px;
  }
  .uk-modal-title {
    img {
      border-radius: @dialog-border-radius 0 0 0;
    }
  }
}
