@import '../../../../styles/variables.less';
@import '../../../../styles/mixins.less';

.room-admin {
  position: relative;

  label {
    display: block;
  }

  input[type='checkbox'],
  input[type='radio'] {
    &:checked {
      background-color: @primaryColor;

      & + .colored-label {
        color: @primaryColor;
      }
    }

    &:focus {
      border-color: @primaryColor;
    }
  }

  .room-url-group {
    margin-top: 14px;

    label {
      position: relative;
    }
  }

  hr {
    margin: 10px 0;
    border-top-color: var(--primary-foreground, #e5e5e5);
  }

  .manage-content-buttons {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;

    button {
      height: 38px;
      padding: 0 22px;
    }
  }

  .link-to-slack,
  .slack-info {
    display: none;

    .slack-channel {
      color: #558;
    }
  }

  .discord-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .discord-integration-button {
    width: 85%;
    height: 40px;
    background: var(--primary-background, @discord);
    color: var(--primary-foreground, white);
    position: relative;
    text-align: left;
    font-weight: bold;

    &.new-integration {
      background: var(--primary-background, white);
      color: var(--primary-foreground, black);

      svg {
        .svg-color(var(--primary-foreground, @discord));
      }
    }

    svg {
      .svg-color(var(--primary-foreground, white));
    }

    .discord-icon-wrapper {
      padding: 20px 10px;
      width: 30px;
    }

    .forward-icon-wrapper {
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .discord-icon {
      height: 25px;
      margin-left: 5px;
    }

    .discord-channel {
      margin-left: 10px;
    }

    .forward-icon {
      width: 30px;
      opacity: 0.7;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 250px;
    }
  }

  .minus-circle-icon-wrapper {
    width: 30px;
    cursor: pointer;

    svg {
      .svg-color(var(--primary-foreground, rgba(180, 180, 180, 0.7)));

      &:hover {
        .svg-color(var(--secondary-foreground-alpha-30, rgba(0, 0, 0, 0.3)));
      }
    }
  }

  .beta-notice {
    font-size: 0.7em;
    background: var(--secondary-background, #ccc);
    text-transform: uppercase;
    font-weight: 500;
    padding: 5px;
    color: var(--secondary-foreground, white);
  }

  .broadcast-section {
    background: var(--primary-background, white);
    color: var(--primary-foreground, black);
    border-radius: 8px;
    padding: 24px 32px;

    .title-container {
      display: flex;

      .title {
        display: inline-block;
        margin: 0 20px 0 10px;
      }

      svg {
        height: 24px;

        .svg-color(var(--primary-foreground, black));
      }
    }

    .text {
      margin: 20px 0;
    }

    .allow-anonymous-viewers {
      margin-bottom: 20px;
    }

    button {
      padding: 16px 24px;
    }
  }

  .update-room-name-error {
    color: red;
  }

  .update-url-error {
    display: none;
    color: red;
  }

  .update-url-success {
    display: none;
    color: green;
    text-align: center;
  }

  .update-url-button {
    display: none;
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 1em;
    width: 100%;
  }

  .change-url-button {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1em;
    width: 100%;
  }

  .update-url-input {
    padding-left: 10px;
    width: 100%;
    height: 36px;
    box-sizing: border-box;
    font-size: 1.05em;
    text-transform: lowercase;
    margin-bottom: 4px;
  }

  .gray-text {
    color: #808080;
  }

  .update-url-copy {
    position: absolute;
    right: 4px;
    background: none;
    height: 36px;
    width: 36px;
    outline: 0;
    border: 0;
    cursor: pointer;

    .svg-color(var(--primary-foreground, @primaryColor));
  }

  .update-url-available-icon,
  .update-url-unavailable-icon {
    display: none;
    position: fixed;
    float: right;
    right: 30px;
    background: none;
    outline: 0;
    border: 0;
  }

  .update-url-available-icon {
    height: 20px;
    width: 20px;
    margin-top: 8px;
  }

  .update-url-unavailable-icon {
    height: 16px;
    width: 16px;
    margin-top: 10px;
  }

  .room-public-group {
    background-color: @primaryColor;
    margin-bottom: 16px;
    border-radius: 12px;
    display: none;

    div {
      padding: 12.5px 16px;
    }

    .room-public-header {
      color: white;
      font-weight: 400;
      display: inline-flex;
      margin: 0;
      margin-left: 10px;
    }

    .room-public-help {
      font-weight: 400;
      display: inline-flex;
      margin: 0;
      float: right;
      color: darkgray;
      text-decoration: underline;
      cursor: pointer;
      display: none;
    }
  }

  .flex-container {
    text-align-last: center;
    justify-content: space-between;
    margin-top: 30px;
    max-width: 1200px;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    height: 90px;
  }

  .flex-child {
    color: rgb(155, 143, 143);

    .gray-section {
      height: 100%;
      border-radius: 5px;
      position: relative;
    }

    .option-section-image {
      margin-top: -22px;
      position: absolute;
      width: 100%;
    }

    .option-section-image-slash {
      margin-top: -22px;
      position: absolute;
      z-index: 1;
      height: 40px;
      content: url(../images/icons/slash.svg);
      display: none;
      width: 100%;
    }

    .permission-section {
      display: inline-block;
      transform: translate(0, 45%);

      h6 {
        margin-bottom: 6px;
        color: var(--primary-foreground, gray);
        font-weight: 600;
      }

      .activated {
        color: var(--primary-foreground, @primaryColor);
      }

      .switch {
        margin: 0 auto;
      }
    }
  }

  .integrations-section {
    color: var(--primary-foreground, black);
  }
}
