@import '../../../../styles/variables.less';
@import '../../../../styles/mixins.less';

@previewDuration: 8s;

.background-properties {
  .bg-help {
    img {
      width: 1em;
      height: 1em;
    }
  }

  .option-left,
  .option-right {
    width: 100%;
    padding: 10px 5px;
    display: inline-block;
  }

  .change-background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #0005;
    display: none;
  }

  @backgroundVerticalPadding: 30%;
  .change-background {
    @height: 50px;
    @width: 150px;

    border: 2px solid white;
    border-radius: @height / 2;
    height: @height;
    width: @width;
    font-size: 1.2em;
    background-color: #00000078;
    margin-left: calc(50% - @width / 2);
    margin-top: calc(@backgroundVerticalPadding - @height / 2);
    outline: none;
    color: white;
    cursor: pointer;
  }

  :hover {
    .change-background-overlay {
      display: block;
    }
  }

  .bg-scrolling-label {
    .svg-color(var(--primary-foreground, black));
  }

  .background-preview {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding: @backgroundVerticalPadding 20%;
    background-color: #8800fe;
    background-position: 50% 50%;

    .bg-pen-indicator {
      background: var(--secondary-background, white);
      border-radius: 50%;
      width: 35px;
      height: 35px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      .bg-pen {
        margin: 7.5px;
        width: 20px;
        height: 20px;

        .svg-color(var(--secondary-foreground, @primaryColor));
      }
    }

    .browser-window {
      border: 2px solid rgb(223, 225, 229);
      border-radius: 8px;
      position: absolute;
      top: 10px;
      right: 20%;
      left: 20%;
      bottom: 10px;

      .browser-tab {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgb(223, 225, 229);
        height: 30px;
        img {
          height: 30px;
        }
      }
    }
  }

  .faux-canvas-object-1 {
    position: absolute;
    top: 20%;
    left: 20%;
    animation-name: move-fg;
    animation-duration: @previewDuration;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  .faux-canvas-object-2 {
    position: absolute;
    top: 30%;
    left: 60%;
    animation-name: move-fg;
    animation-duration: @previewDuration;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  .canvas-movement {
    animation-name: move-bg;
    animation-duration: @previewDuration;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  .bg-color-select {
    border-radius: 5px;
    border: 1px solid black;
    width: 50px;
    height: 1.5em;

    &[disabled] {
      opacity: 0.5;
    }
  }

  label {
    color: var(--primary-foreground, inherit);
  }

  select {
    display: block;
    width: 100%;
  }

  .disabled {
    color: #ddd;

    body.themed & {
      color: var(--primary-foreground-alpha-50);
    }
  }

  .lower-option-label {
    line-height: 1.5em;
    vertical-align: middle;
    padding-right: 15px;
  }

  input.bg-size {
    width: 75%;
    height: 0.5em;
  }
}

// TODO: Animate with 'transform' instead of expensive margin-animation.
@keyframes move-fg {
  from {
    margin-left: 0;
    margin-top: 0;
  }
  to {
    margin-left: 100px;
    margin-top: 50px;
  }
}

@keyframes move-bg {
  from {
    background-position: calc(50% - 50px) calc(50% - 25px);
  }
  to {
    background-position: calc(50% + 50px) calc(50% + 25px);
  }
}
