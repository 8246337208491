@import '../../../styles/variables.less';

// web + electron app styles
.feedback-updates {
  overflow: auto;
  height: 425px;
  padding-right: 5px;
}

.new-update-tag {
  margin: 0;
  padding: 2px 6px;
  display: inline-block;
  color: white;
  font-size: 10px;
  font-weight: 600;
  line-height: 22px;
  background-color: @newUpdateColor;
  border-radius: 4px;
}

.update-item {
  padding: 12px 12px 18px 12px;
  text-align: justify;

  p {
    margin: 0;
  }

  .update-title-block {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
  }

  &__image {
    display: none;
  }

  &__title {
    margin: 0;
    color: var(--primary-foreground, black);
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }

  &__date {
    color: var(--primary-foreground, #141414);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  &__description {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--primary-foreground-alpha-80, rgba(80, 80, 80, 0.8));
  }

  &__learn-more {
    color: var(--primary-foreground, black);
    text-decoration: underline;

    &:hover {
      color: var(--secondary-foreground, #1e87f0);
    }
  }

  &:not(:first-child) {
    border-top: 1px solid var(--primary-foreground, #e5e5e5);
  }
}

// electron app styles

.electron-lobby-whats-new-area {
  .update-item {
    padding: 18px 0;
    background: inherit;
    border-bottom: 0.25px solid #ffffff40;
    border-radius: 0;

    .new-update-tag {
      display: none;
    }

    .update-title-block {
      margin: 9px 0;
    }

    &__image {
      width: 100%;
      display: block;
      border-radius: 5px;
    }

    &__title {
      color: #ffffff;
      font-family: 'Inter Extra Bold';
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      opacity: 0.9;
    }

    &__date {
      color: #ffffff;
      font-weight: 400;
      line-height: px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      opacity: 0.5;
    }

    &__description {
      margin-top: 7px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: initial;
      font-feature-settings: 'tnum' on, 'lnum' on;
      opacity: 0.75;

      .feedback-link {
        display: block;
        color: #ffffff;
        text-transform: uppercase;
        line-height: 28px;

        &::after {
          content: ' ';
          margin-top: 8px;
          margin-left: 4px;
          position: absolute;
          height: 9px;
          width: 7px;
          display: inline-block;
          opacity: 0.9;
          background: url(../images/icons/feedback/chevron-white.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }

    &__learn-more {
      margin-top: 16px;
      width: fit-content;
      display: block;
      color: #ffffff;
      font-size: 13px;
      text-transform: uppercase;
      opacity: 0.9;
      letter-spacing: 0.075rem;
      font-feature-settings: 'tnum' on, 'lnum' on;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &::after {
        content: '->';
        position: absolute;
        margin-top: -1px;
        margin-left: 4px;
        display: inline-block;
        letter-spacing: normal;
      }
    }
  }
}
