.here-audio-icon {
  padding-top: 5px;
  padding-bottom: 10px;
  height: 22px;
  width: auto;
}

.here-audio-slider-wrapper {
  height: 84%;
  width: 33px;
}

.here-audio-slider-master {
  border-radius: 20px;
  padding: 12px 0;
  padding-bottom: 20px;
  height: 200px;
  background-color: #ffffff;
  cursor: pointer;
}

.here-audio-slider-track {
  height: 100%;
  width: 8px;
  position: relative;
  background-color: #ddd;
  margin: auto;
  transform: rotate(180deg);
  border-radius: 20px;
  cursor: pointer;
}

.here-audio-slider {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #6b1be3;
  border-radius: 20px;
  cursor: pointer;
}
