@import './variables.less';

.share-room-master,
.share-room-master-top {
  background: var(--primary-background, @menuBackground);
  width: 100%;
}

.share-room-master {
  height: 105px;
}

.share-room-master-top {
  height: 80px;
  display: flex;
}

.share-room-div {
  background: var(--primary-background, @menuBackground);
  position: relative;
  display: none;
}

.share-room-directions {
  color: var(--primary-foreground, white);
  text-align: center;
  padding-top: 10px;
  margin-bottom: 0;
}

.share-room-submit {
  float: right;
  margin: 10px;
  height: 45px;
  border-radius: 4px;
  width: 160px;
  font-size: 1em;
  margin-right: 20px;
}

.share-room-input {
  float: left;
  color: white;
  height: 40px;
  margin: 10px;
  width: calc(100% - 240px);
  margin-left: 20px;
  color: black;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 1.1em;
}

.instructions-directions {
  color: white;
  padding-top: 14px;
  float: left;
  height: 40px;
  margin: 10px;
  width: calc(100% - 240px);
  margin-left: 20px;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 1.1em;
}

.instructions-close {
  float: right;
  margin: 15px;
  height: 45px;
  border-radius: 4px;
  background-color: @primaryColor;
  color: white;
  width: 160px;
  font-size: 1em;
  margin-right: 20px;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.instructions-div {
  background-color: @focuscolor;
  display: none;
}

.how-to-start-button {
  height: 40px;
}
