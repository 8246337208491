@import '../../../styles/variables.less';
@import '../../../styles/mixins.less';

body.user-card .invite-menu {
  display: none !important;
}

.users-bar {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 10005;
  -webkit-app-region: none;

  &.electron-windows {
    top: 15px;
  }

  .users-number-container {
    display: none;
  }

  .invite-button-divider {
    display: none;
    border-right: 1px solid var(--primary-foreground, #606060);
    height: 20px;
    margin: 0 10px;
  }

  @media (max-width: 1100px) {
    .users-list {
      display: none !important;
    }

    .invite-button-divider {
      display: block;
    }

    .users-number-container {
      margin-left: 15px;
      margin-right: 5px;
      display: flex;

      svg {
        margin-right: 5px;
        margin-bottom: 4px;

        .svg-color(var(--primary-foreground, white));
      }

      .users-number {
        color: var(--primary-foreground, white);
        font-weight: 500;
      }
    }
  }

  .inner-bar {
    display: flex;
    align-items: center;
    background: var(--primary-background, @menuBackground);
    border-radius: @topMenuHeight / 2;
    height: @topMenuHeight;
    padding: 4px;
    box-sizing: border-box;

    .user-bar-divider {
      width: 0;
      height: 23.5px;
      opacity: 0.3;
      border-left: 1px solid var(--primary-foreground, white);
      margin-right: 11px;
      margin-left: 8px;
    }

    .users-list {
      display: flex;
      align-items: center;

      .settings-button-container {
        .bar-item {
          margin-right: -20px !important;
          flex-shrink: 0;
          width: 38px;
          height: 38px;

          .avatar {
            border-radius: 50%;
            box-sizing: border-box;
          }
        }
      }

      div:last-of-type {
        .bar-item {
          margin-right: 0 !important;
        }
      }
    }

    .delimiter {
      height: 80%;
      width: 1px;
      background-color: white;
      opacity: 0.3;
      margin: 0 4px;
    }

    .invite-button,
    .users-list .presence-icon {
      margin: 0 2px;
    }

    .invite-button {
      @height: 32px;

      background: var(--secondary-background, @primaryColor);
      color: var(--secondary-foreground, white);
      text-align: center;
      cursor: pointer;
      border-radius: 20px;
      height: @height;
      width: 94px;
      line-height: @height;
      font-weight: bold;
      transition: transform 0.2s ease-out;

      &:hover {
        transform: scale(1.05);
      }
    }

    .invite-bar-icon {
      height: 12px;
      margin-right: 6px;
      margin-bottom: 2px;
      .svg-color(var(--secondary-foreground, white));
    }

    .invite-menu {
      background: var(--primary-background, white);
      right: 10px;
      top: 61px !important;
      left: auto !important;
      padding: 17px 0 0;
      border-radius: 12px;
      width: 409px;
      max-height: calc(100vh - 120px);
      overflow-y: auto;

      &.uk-dropdown-bottom-right {
        margin-top: 18px !important;
      }
    }

    @media (max-width: 1100px) {
      .invite-menu {
        right: 2px;
        top: 52px !important;

        &::before {
          display: none;
        }
      }
    }

    .notification-count {
      background: #f6335d;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 2px solid #12002d;

      display: flex;
      justify-content: center;
      align-items: center;

      color: #ffffff;
      font-weight: 600;
      font-size: 10px;

      position: absolute;
      top: 3px;
      right: 3px;
    }
  }

  @media (max-width: 1100px) {
    .inner-bar {
      padding: unset;
      background: unset;
      border-radius: 0;

      .notification-count {
        top: -6px;
        right: -6px;
      }
    }
  }

  #publicize-room-confirmation {
    width: 350px;
    padding: 20px;

    hr {
      border-color: var(--primary-foreground);
    }

    .publicize-share-button {
      width: 49%;
      padding: 0;
      height: 46px;
    }

    .publicize-twitter-button {
      background-color: #1da1f2;
      color: white;
      border-radius: 10px;
    }

    .publicize-button-icon {
      margin-right: 4px;
    }

    .invite-share-header {
      vertical-align: text-top;
      margin-left: 4px;
      color: var(--primary-foreground, black);
    }

    .invite-share-header-parent {
      margin-bottom: 10px;

      .svg-color(var(--primary-foreground, @primaryColor));
    }

    .invite-share-subtitle {
      margin: 0 0 10px 0;
      line-height: 1.5;
      color: var(--primary-foreground, black);
    }

    .buttons-section {
      display: flex;
      justify-content: space-between;
    }

    .publicize-notice-section {
      margin-top: 10px;
    }
  }

  #invite-share-button {
    display: none;
    position: absolute;
  }
}

@media (max-width: 613px) {
  .discord-invite-modal {
    top: 52px !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
    min-width: 355px !important;
    max-width: 80%;

    .invite-container {
      min-width: unset;

      .invite-text-comments {
        margin-top: 18px;
      }
    }

    .invite-text-comments {
      flex-direction: column;
    }
  }
}

@media (max-width: 1100px) {
  .users-bar {
    padding: 1px 5px;
    margin-top: 10px;
    margin-right: 10px;
    height: @topMenuHeight;
    background: var(--primary-background, @menuBackground);
    border-radius: @topMenuHeight / 2;
  }

  .inner-bar {
    box-shadow: none;
  }
}

.settings-button-container {
  margin-left: 4px;

  &:first-child {
    margin-left: 0px;
  }

  .settings-button {
    cursor: pointer;

    &::before {
      display: none;
      content: '⋯';
      font-size: 23px;
      color: @menuBackground;
      background-color: white;
      height: 37px;
    }

    &:hover::before {
      display: block;
    }
  }
}
