@import './variables.less';

// TODO: This component should be burnt in fire and re-written

.upload-notification-container {
  background-color: white;
  height: 70px;
  border-radius: 12px;
  overflow: hidden;
  width: 675px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.7);
  margin-top: 12px;
  margin-bottom: 12px;
}

.upload-notification-section {
  height: 100%;
  float: left !important;
}

.icon-section {
  width: 135px;
}

.upload-notification-icon {
  color: white;
  height: 40px;
  width: 40px;
  margin: auto;
  object-fit: contain;
  transform: translate(123%, 35%) !important;
}

.message-section {
  margin-left: 26px !important;
  width: 250px;
  transform: translate(0%, 120%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.message-section-error {
  margin-left: 26px !important;
  width: 400px;
  transform: translate(0%, 120%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button-section {
  width: 100px;
}

.upload-notification-error {
  background-color: #f6335d;
}

.upload-notification-text {
  background-color: #1b77e4;
}

.upload-notification-text::-webkit-progress-value {
  background: #1b77e4 !important;
}

.upload-notification-text::-moz-progress-bar {
  background: #1b77e4 !important;
}

.upload-notification-audio {
  background-color: #ff72ff;
}

.upload-notification-audio::-webkit-progress-value {
  background: #ff72ff !important;
}

.upload-notification-audio::-moz-progress-bar {
  background: #ff72ff !important;
}

.upload-notification-video {
  background-color: #f6335d;
}

.upload-notification-video::-webkit-progress-value {
  background: #f6335d !important;
}

.upload-notification-video::-moz-progress-bar {
  background: #f6335d !important;
}

.upload-notification-presentation {
  background-color: #f6a730;
}

.upload-notification-presentation::-webkit-progress-value {
  background: #f6a730 !important;
}

.upload-notification-presentation::-moz-progress-bar {
  background: #f6a730 !important;
}

.upload-notification-spreadsheet {
  background-color: #39c360;
}

.upload-notification-spreadsheet::-webkit-progress-value {
  background: #39c360 !important;
}

.upload-notification-spreadsheet::-moz-progress-bar {
  background: #39c360;
}

.upload-notification-image {
  background-color: #16c7ff;
}

.upload-notification-image::-webkit-progress-value {
  background: #16c7ff !important;
}

.upload-notification-image::-moz-progress-bar {
  background: #16c7ff !important;
}

.upload-notification-unknown {
  background-color: #959494;
}

.upload-notification-unknown::-webkit-progress-value {
  background: #959494 !important;
}

.upload-notification-unknown::-moz-progress-bar {
  background: #959494 !important;
}

.upload-notification-pdf {
  background-color: #ff0000;
}

.upload-notification-pdf::-webkit-progress-value {
  background: #ff0000 !important;
}

.upload-notification-pdf::-moz-progress-bar {
  background: #ff0000 !important;
}

.action-button-section {
  height: 100%;
  width: 200px;
  float: right !important;
}

.action-button {
  margin: auto;
  width: 80px;
  height: 30px;
  transform: translate(150%, 70%);
}

.uk-progress {
  height: 7px;
}

.upload-bar {
  transform: translate(0%, 430%);
  width: 85% !important;
  height: 8px !important;
  background-color: #c4c4c4 !important;
  margin-left: 6px;
}

.upload-bar-section {
  height: 100%;
  width: 225px;
  float: right !important;
}

body.user-card {
  .notifications-container-bottom {
    transform: translateX(-50%) scale(42%) translateY(180px);
  }
}
