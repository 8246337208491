@import '../../../styles/variables.less';
@import '../../../styles/mixins.less';

here-broadcast-camera-invite {
  .notification.broadcast-camera-invite {
    width: fit-content;

    .left-area {
      padding: 0;
      align-items: center;

      svg {
        height: 50px;
        width: 50px;

        .svg-color(var(--secondary-foreground, white));
      }
    }
  }
}
