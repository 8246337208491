#room-capacity-dialog {
  text-align: center;

  .title {
    font-size: 18px;
    margin: 8px 0 12px;
    place-content: center;
  }

  .title-image {
    margin-bottom: 20px;

    img {
      height: 400px;
    }
  }

  .capacity-container {
    margin-bottom: 20px;

    .capacity {
      color: #f00;
    }

    .capacity-icon {
      height: 20px;
      position: relative;
      top: -2px;
    }
  }

  .retry-button {
    width: 60%;
    color: white;
  }
}
