.font-select {
  width: 55% !important;
  border: none !important;
  padding-right: 0 !important;
  background: var(--primary-background, white) !important;
}

.font-size-select {
  width: 24% !important;
  background-image: none !important;
  border: none !important;
}

.font-dropdown-icon {
  background-image: url('images/icons/down-black.svg');
  background-repeat: no-repeat;
  background-size: 7px;
  background-position: center;
  height: 7px;
  width: 7px;
  margin-bottom: 3px !important;
}

.dropdown-button {
  background: none !important;
  font-size: 14px !important;
  color: var(--primary-foreground, black) !important;

  .dropdown-font-family {
    margin-bottom: 3px !important;
  }

  .dropdown-icon::before {
    border-top-color: var(--primary-foreground, black) !important;
  }
}

.font-size-select-container {
  margin-left: 4%;
  margin-right: 6%;
}

.font-dropdown {
  height: 30px;
  background: var(--primary-background, #eaeaea);
  border: none;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
  width: 100% !important;
  border-radius: 4px;
}

.font-size-dropdown {
  height: 30px;
  border: none;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
  width: 60px;
  border-radius: 4px;
}

.uk-dropdown.font-size-dropdown-menu {
  background: var(--primary-background, white);

  .font-option {
    color: var(--primary-foreground, #666);

    &:hover {
      background: var(--secondary-background, #ddd);
      color: var(--secondary-foreground, #666);
    }
  }
}

// if you'll search for font-list you won't find it
// new FontPicker() creates it somehow ¯\_(ツ)_/¯
.font-list {
  background: var(--primary-background, white) !important;
  bottom: 100%;
  color: var(--primary-foreground, black);

  .font-button {
    &:hover,
    &.active-font {
      background: var(--secondary-background, #ddd);
      color: var(--secondary-foreground, black);
    }
  }
}

// I have no idea what it's target
option:empty {
  display: none;
}
