/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/inter/inter-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Inter'), url('/fonts/inter/inter-v3-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/inter/inter-v3-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/inter/inter-v3-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/inter/inter-v3-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/inter/inter-v3-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local('Inter'), url('/fonts/inter/inter-v12-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/inter/inter-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-800 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: local('Inter'), url('/fonts/inter/inter-v12-latin-800.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/inter/inter-v12-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
