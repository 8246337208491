@import './variables.less';

#screen-share-notice {
  .uk-modal-dialog {
    width: 700px;
    border-radius: 10px;
    padding: 10px 10px 38px 10px;
  }

  .screen-share-notice-image {
    width: 100%;
    background-color: #6ccedb;
    border-radius: 10px;
    padding: 20px 70px 10px 50px;
  }

  .screen-share-notice-text {
    width: 80%;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
  }

  .screen-share-notice-header {
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    font-weight: bold;
    margin-top: 30px;
  }

  .screen-share-notice-button-section {
    width: 50%;
    margin: auto;
  }

  #screen-share-notice-confirm-button {
    margin: auto;
    display: block;
    padding: 0px 41px;
    background-color: @menuBackgroundHover;
    border-radius: 10px;
  }
}
