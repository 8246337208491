here-broadcast-camera-requests {
  .notification.broadcast-request {
    width: max-content;

    .left-area {
      align-items: center;
      background-color: #e5e5e5;
      padding: 0;

      .avatar-container {
        @size: 40px;

        clip-path: circle(50%);
        width: @size;
        height: @size;
      }
    }
  }
}
