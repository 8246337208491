@import './variables.less';
@import './mixins.less';

.message-creator {
  font-size: 0.7rem;
  padding-top: 0.2em;
  padding-left: 5px;
  padding-bottom: 0;
  color: #fff;
  text-shadow: 1px 1px #333;

  .message-date {
    color: #999;
  }
}

.message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-wrap: break-word;
}

.message-container.room-card-message {
  margin-top: 8px;
}

.bottom-message-bubble {
  border-radius: 10px 10px 10px 0;
}

.bottom-message-bubble-self {
  border-radius: 10px 10px 0 10px;
}

.message-bubble {
  border-radius: 10px;
}

.message-bubble,
.image-message-bubble,
.bottom-message-bubble-self,
.bottom-message-bubble {
  display: inline-block;
  position: relative;
  color: #eee;
  background-color: @primaryColor;
  pointer-events: all;
  margin: 0;
  padding: 7px 5px;
  vertical-align: middle;
  line-height: 1.2em;
  box-shadow: 1px 1px 3px #0008;

  &.inside-decorator {
    overflow: hidden;
  }

  .message-text {
    position: relative;
    padding: 1px 4px;
    overflow-wrap: anywhere;
    user-select: text;
    cursor: text;
  }

  .message-emoji {
    overflow-wrap: anywhere;
    font-size: 50px;
    line-height: 50px;
  }

  a,
  a:hover {
    color: inherit;
  }

  img {
    max-width: 75px;
    max-height: 75px;
    object-fit: fill;
    padding-right: 5px;
  }

  .sticker {
    max-width: 200px;
    max-height: 200px;
  }

  p {
    font-size: 16px;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px 0 10px 0;
    color: #444;
  }

  h2 {
    font-size: 1.1em;
    padding: 0;
    margin: 0;
  }

  .message-add-element-prompt {
    text-align: center;
  }

  .hidden {
    display: none;
  }

  .embed-element-button {
    display: inline-block;
    border-radius: 12px;
    background-color: #6b1be3;
    border: none;
    width: 200px;
    font-size: 1.2em;
    font-weight: normal;
    color: #fff;
    cursor: pointer;
    max-width: 100%;
    padding: 0.5rem 1rem;

    &:hover {
      text-decoration: none;
    }
  }
}

.image-message-bubble {
  padding: 0;
  background: none;
  box-shadow: none;
  background-color: transparent !important;

  img {
    max-width: 250px;
    max-height: unset;
    padding: 0;
    border-radius: 10px;
  }

  .feed-item-image-content {
    max-width: 100%;
  }
}

.bottom-message-bubble-self,
.bottom-message-bubble {
  &.image-message-bubble {
    img {
      border-bottom-left-radius: 0;
    }
  }
}

.bottom-message-bubble-self {
  &.image-message-bubble {
    img {
      border-bottom-right-radius: 0;
    }
  }
}

.message-link-summary {
  background-color: rgb(237, 237, 237);
  margin-top: 5px;
  padding: 5px;
  pointer-events: all;
  color: #444;
  text-decoration: none;
  border-radius: 0 0 10px 10px;
  display: grid;
  grid-template-areas: 'message-link-thumbnail message-link-title' 'message-link-thumbnail message-link-description' 'message-link--embed message-link--embed' 'message-link--add-element-prompt message-link--add-element-prompt';
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;

  .message-link-image {
    grid-area: message-link-thumbnail;
  }

  .message-link-title {
    grid-area: message-link-title;
  }

  .message-link-description {
    grid-area: message-link-description;
  }

  .message-add-element-prompt {
    grid-area: message-link--add-element-prompt;
    margin-top: 4px;
  }
}

.sticker-bubble {
  background-color: transparent;
  box-shadow: none;
}

// TODO: revise styles below
.chat-item-context-buttons {
  @chatItemContextButtonSize: 20px;

  position: absolute;
  top: calc(-@chatItemContextButtonSize / 2);
  right: calc(-@chatItemContextButtonSize / 2);
  z-index: 10;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;

  .chat-item-context-button {
    padding: 3px;
    height: @chatItemContextButtonSize;
    width: @chatItemContextButtonSize;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      display: flex;
      background-color: #ececec;
    }
  }
}

.local-message {
  .chat-item-context-buttons {
    display: none !important;
  }
}

.feed-text-message {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 7px;

  .feed-text-message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.feed-text-message,
.feed-image-message {
  display: flex;

  .feed-text-message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .message-container {
    position: relative;
  }

  .delete-message-button {
    position: absolute;
    top: -5px;
    right: -2px;
    width: 15px;
    height: 15px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #000;
    font-size: 11px;
    border: none;
    border-radius: 50%;
    background-color: @offColor;
    pointer-events: all;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: @transitionSpeed;
    transition-duration: @transitionSpeed;
    -webkit-transition-property: transform;
    transition-property: transform;

    &:hover {
      background-color: #e74f81;
      -webkit-transform: @transitionScale;
      transform: @transitionScale;
    }
  }
}

.feed-image-message {
  position: relative;

  .message-container:hover {
    cursor: pointer;
  }
}

.feed-system-message {
  color: white;
  font-size: 0.7rem;
  text-shadow: 1px 1px #333;
  font-weight: 500;
  line-height: 20px;
  padding-left: 48px;
  font-family: var(--chat-system-font, Inter, sans-serif);

  .feed-system-message-text {
    &::before {
      content: ' ';
      position: relative;
      width: 5px;
      display: inline-block;
    }
  }
  .friending-button {
    width: 135px;
    height: 30px;
    font-size: 10px;
    font-weight: 700;
    column-gap: 6px;

    .svg-color(var(--secondary-foreground, white));
    color: var(--secondary-foreground, white);
    background: var(--secondary-background, @primaryColor);

    &:hover {
      background: var(--secondary-background-alpha-80, rgba(@primaryColorRgb, 0.8));
      text-decoration: none;
      color: var(--secondary-foreground, white);
    }
  }

  button {
    color: #aaaaaa;
    font-size: 0.6rem;
    border: none;
    background-color: inherit;
    cursor: pointer;
    pointer-events: all;

    &:hover {
      color: #0f6ecd;
      text-decoration: underline;
    }
  }
}
