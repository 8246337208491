.whiteboard {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.whiteboard-rasterized-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5, 0.5);
  max-width: unset;
}

.whiteboard-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.whiteboard-drawing-tip {
  z-index: 10000;
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  background-color: white;
  border-radius: 8px;
  padding: 2px 10px;
  overflow: hidden;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
}

.whiteboard-bg-options {
  display: flex;
  justify-content: space-between;
}

.whiteboard-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}

.whiteboard-overlay-message {
  position: absolute;
  top: calc(50% - 0.6em);
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  font-weight: 450;

  .boardElement:not(.can-move) & {
    display: none !important; // Override inline styles
  }
}

.boardElement:hover .whiteboard-drawing-tip {
  display: inline-flex;
}
