@import '../../../styles/variables.less';
@import '../../../styles/mixins.less';

// web app

.feedback-tabs-content {
  &--closed {
    display: none;
  }
}

.feedback-help-buttons {
  .contact-us-item {
    padding: 5px 10px;
    display: grid;
    grid-template-areas: 'icon title' 'icon description';
    grid-template-columns: auto 1fr;
    border-radius: 5px;
    cursor: pointer;

    .contact-us-item-icon-wrapper {
      padding: 20px 20px 20px 0;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-area: icon;

      .background {
        fill: var(--secondary-background, #e8e8e8) !important;
      }

      .svg-color(var(--secondary-foreground, #11012a));
    }

    .contact-us-item__title {
      margin: 0;
      display: inline-block;
      grid-area: title;
      align-self: flex-end;
      color: var(--primary-foreground, @primaryColor);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      .svg-color( var(--primary-foreground, @primaryColor));
    }

    .contact-us-item__description {
      margin: 0;
      grid-area: description;
      color: var(--primary-foreground, black);
      opacity: 0.9;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    &:hover {
      background: var(--secondary-background, @primaryColor);

      .contact-us-item__title {
        color: var(--secondary-foreground, white);
        .svg-color(var(--secondary-foreground, white));
      }

      .contact-us-item__description {
        color: var(--secondary-foreground, white);
      }

      .contact-us-item-icon-wrapper {
        .background {
          fill: var(--secondary-foreground, #e8e8e8) !important;
        }

        .svg-color(var(--secondary-background, #11012a));
      }
    }
  }

  hr {
    margin: 0;
    border-color: var(--primary-foreground, #e5e5e5);
  }

  .feedback-input-block--closed {
    display: none;
  }

  .feedback-response {
    margin: 0 5px;

    .feedback-response__text {
      display: inline-block;
    }
  }
}

.feedback-send-button {
  margin-top: 17px;
  margin-bottom: 20px;
  height: 40px;
  width: 100%;
  color: #ffffff;
  font-family: Inter;
  font-size: 1em;
  background-color: @primaryColor;
  border-radius: 12px;
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 40px;
  cursor: pointer;
  opacity: 0.95;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
}

.feedback-textarea {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 10px;
  border-color: @secondaryTextColor;
  width: 92%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  resize: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  &:focus {
    border-color: @primaryColor;
  }

  &::placeholder {
    color: @secondaryTextColor;
  }
}

.feedback-error-message {
  color: red;
  margin-top: 4px;
  margin-bottom: 14px;
  text-align: center;
}

.feedback-response__text {
  display: inline-block;
  font-size: 0.95em;
  text-align: center;
  color: black;
}

.invite-us-modal-title {
  font-size: 1.2em !important;
  color: black;
  font-weight: 600;
}

.contact-us-form-content {
  &--closed {
    display: none;
  }
}

.contact-us-invite-us-item-cancel-button {
  // Overriding UIKit styles for close button :(
  color: black !important;
  background-color: lightgray !important;
  position: inherit !important;
  padding: 0 30px !important;
}

#contact-us-invite-us-item-confirm-button {
  color: white !important;
  background-color: @primaryColor !important;
}

.contact-us-purple-button {
  color: white !important;
  background-color: @primaryColor !important;
}

.feedback-help-buttons--closed {
  display: none;
}

.contact-us-form-content {
  hr {
    margin: 0;
  }

  .contact-us-form-title-block {
    padding-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contact-us-form__title {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: @primaryColor;
    }

    .contact-us-form__close-button {
      border: none;
      background-color: inherit;
      cursor: pointer;
      opacity: 0.65;

      &:hover {
        opacity: 1;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .contact-us-form__subtitle {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .contact-us-form-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .contact-us-form__cancel-button {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #818181;
      text-decoration: underline;
      opacity: 0.75;
      background-color: inherit;
      border: none;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &--closed {
    display: none;
  }
}

.feedback-menu-tab {
  .contact-us-form-title-block {
    margin-top: 10px;
  }

  .feedback-help-buttons {
    height: 528px;
  }

  .contact-us-form-content {
    height: 430px;
  }

  .feedback-response {
    margin: -5px 0 0 0;
  }
}

// electron app

.electron-lobby-feedback-area {
  .feedback-help-buttons {
    .contact-us-item {
      padding: 10px 5px;
      background-color: #5c27a0;

      &-icon-wrapper {
        padding: 0;
        margin-right: 5px;
        opacity: 0.5;
      }

      &__title {
        color: #ffffffe6;
        font-size: 14px;

        &::after {
          content: ' ';
          margin-left: 9px;
          height: 8px;
          width: 10px;
          display: inline-block;
          opacity: 0.9;
          background: url(../images/icons/feedback/chevron-white.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      &__description {
        color: #ffffffbf;
        font-size: 12px;
        line-height: 18px;
      }

      &:hover {
        .contact-us-item-icon-wrapper {
          opacity: 1;
        }

        .contact-us-item__title {
          color: #ffffff;

          &::after {
            content: ' ';
            margin-left: 9px;
            height: 8px;
            width: 10px;
            display: inline-block;
            opacity: 0.9;
            background: url(../images/icons/feedback/chevron-white.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        .contact-us-item__description {
          color: #ffffff;
        }
      }
    }

    hr {
      margin: 4px 0px;
      visibility: hidden;
    }
  }

  // you can't invite us to the lobby :D
  #contact-us-invite-us-item,
  #contact-us-invite-us-item-confirm,
  #contact-us-invite-us-item-confirm-response {
    display: none;
  }

  .contact-us-form-content {
    .contact-us-form-title-block {
      .contact-us-form__title {
        color: #ffffff;
      }

      .contact-us-form__close-button {
        visibility: hidden;
      }
    }

    .contact-us-form__subtitle {
      color: #ffffffb9;
    }

    .feedback-textarea {
      width: 100%;
      color: #ffffff80;
      background-color: #ffffff1a;
      border: 1px solid #ffffff33;
    }

    .feedback-send-button {
      color: #612cda;
      background-color: #ffffff;
    }

    .contact-us-form__cancel-button {
      color: #ffffff;
    }
  }
}
