@import '../../../styles/variables.less';
@import '../../../styles/mixins.less';

#here-toolbar {
  display: flex;
  align-items: end;

  &.hidden,
  body.user-card & {
    display: none;
  }
}

#room-menu-bar {
  &.onboarding-disabled {
    // override default from the onboarding.less
    pointer-events: all !important;
    opacity: 1 !important;
    background: var(--primary-background-alpha-50, rgba(18, 0, 45, 0.5));
  }
}

#color-select-dropdown {
  min-width: 270px;
}

.menu-button {
  background-color: transparent;
  position: relative;
  width: 50px;
  height: 28px;
  border-radius: 30px;
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 40px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition-duration: @transitionSpeed;
  transition-duration: @transitionSpeed;
  -webkit-transition-property: transform;
  transition-property: transform;

  &.games-picker-button {
    padding-bottom: 2px;
  }

  &.etc-button {
    display: none;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
      display: none;
    }

    img {
      width: 40%;
    }
  }

  svg {
    .svg-color( var(--primary-foreground, white));
  }

  &:hover {
    background-color: var(--secondary-background, @menuBackgroundHover);
    transform: @transitionScale;

    svg {
      .svg-color(var(--secondary-foreground, white));
    }
  }

  &:focus {
    outline: none;
  }
}

.etc-tools-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  justify-items: center;
  align-items: center;

  .menu-button {
    padding: 0;
    height: 63px;
    width: 100%;
    min-width: 63px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--primary-foreground, white);
    border-radius: 5px;

    .img-wrapper {
      margin-bottom: 5px;
      height: 29px;
      width: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      background: var(--secondary-background, #23133b);
      color: var(--secondary-foreground, white);
      transform: none;
    }
  }

  .screenshare-buttons {
    width: 100%;
    display: none;

    .screenshare-off-button:hover {
      background-color: #72c55e;
    }
  }
}

#etc-tools-dropdown {
  padding: 15px;
  width: unset !important;
  color: white;
  background: var(--primary-background, @menuBackground);
}

#add-warp-list {
  display: none;
  padding: 0 10px;
}

#add-warp-items {
  a {
    color: #666;
  }

  li:hover {
    background-color: #e0e0e0;
  }
}

.warp-header {
  border-bottom: 1px solid #ddd;
}

#add-warp-container {
  overflow-y: scroll;
  padding-top: 3px;
}

#game-select-dropdown,
#build-tools-dropdown,
#sticker-select,
#file-upload-dropdown,
#room-decoration-select {
  border-radius: 20px;
  background: var(--primary-background, white);
}

#room-decoration-select {
  width: 700px !important;

  .uk-tab {
    flex-wrap: nowrap;

    @media (max-width: 829px) {
      a {
        padding: 5px;
        min-width: unset;
        font-size: 13px;
      }
    }

    @media (max-width: 560px) {
      a {
        font-size: 12px;
      }
    }

    @media (max-width: 495px) {
      a {
        font-size: 10px;
      }
    }
  }
}

#game-select-dropdown,
#add-warp-list,
#build-tools-dropdown {
  width: unset !important;
  min-width: 340px;

  .game-list,
  .build-tools-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 7.5px;

    li {
      display: flex;
      justify-content: center;

      a {
        height: 120px;
        width: 120px;
        min-height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--primary-foreground, #12002c);
        border-radius: 5px;

        .emoji {
          font-size: 24px;
        }

        &:hover {
          background: var(--secondary-background, #e0e0e0);
          color: var(--secondary-foreground, inherit);
        }
      }
    }
  }
}

@media (max-width: 946px) {
  #sticker-select {
    left: 0 !important;
    width: 60vw !important;
  }

  #room-decoration-select {
    width: 80vw !important;
  }

  .menu-button.etc-button {
    display: flex;

    .plus-white {
      display: none;
    }

    .cross-black {
      width: 15px;
      display: none;
    }

    &.active {
      background: var(--secondary-background, @primaryColor);
      .svg-color(var(--secondary-foreground, white));

      &.highlight-round-pulse {
        &::before,
        &::after {
          // during the onboarding don't pulsate when it's already open
          display: none;
          animation: none;
        }
      }

      .plus-white,
      .etc-white {
        display: none;
      }

      .cross-black {
        display: block;
      }
    }
  }

  .new-element-buttons-divider,
  .feedback-menu-button,
  .build-tools-button,
  .pen-color-indicator-button {
    display: none;
  }

  .etc-tools-grid {
    .feedback-menu-button,
    .build-tools-button,
    .pen-color-indicator-button {
      display: flex;
    }
  }
}

@media (max-width: 815px) {
  .add-drawing-button {
    display: none;
  }

  .etc-tools-grid {
    .add-drawing-button {
      display: flex;
    }
  }
}

@media (max-width: 764px) {
  #sticker-select {
    width: 75vw !important;
  }

  #room-decoration-select {
    width: 80vw !important;
  }

  .games-picker-button {
    display: none;
  }

  .etc-tools-grid {
    .games-picker-button {
      display: flex;
    }
  }
}

@media (max-width: 713px) {
  .new-media-player-button {
    display: none;
  }

  .etc-tools-grid {
    .new-media-player-button {
      display: flex;
    }
  }
}

@media (max-width: 689px) {
  .open-room-decoration-button {
    display: none;
  }

  .etc-tools-grid {
    .open-room-decoration-button {
      display: flex;
    }
  }
}

@media (max-width: 665px) {
  .open-gifs-button {
    display: none;
  }

  .etc-tools-grid {
    .open-gifs-button {
      display: flex;
    }
  }
}

@media (max-width: 613px) {
  .room-menu-bar {
    .new-element-buttons {
      position: static;
    }

    .menu-button {
      display: none;
    }

    .etc-tools-grid {
      .menu-button,
      .screenshare-buttons {
        display: flex;
      }

      .menu-button {
        min-height: 90px;
      }
    }
  }

  #game-select-dropdown,
  #etc-tools-dropdown,
  #sticker-select,
  #build-tools-dropdown {
    width: 95% !important;
    left: 2.5% !important;
    right: 2.5% !important;
  }

  #room-decoration-select {
    left: 0 !important;
    width: 98% !important;
  }

  #game-select-dropdown,
  #build-tools-dropdown {
    .game-list,
    .build-tools-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      li {
        a {
          min-height: 90px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: var(--primary-foreground, @menuBackground);
          border-radius: 5px;

          .emoji {
            font-size: 24px;
          }
        }
      }
    }
  }

  .menu-button {
    &.etc-button {
      display: flex;

      .etc-white {
        display: none;
      }

      .plus-white {
        display: block;
      }

      // this button should be enabled during the onboarding on narrow screens
      &.onboarding-disabled {
        pointer-events: all !important;
        opacity: 1;
      }

      &::before,
      &::after {
        display: block;
      }
    }
  }
}

#disabled-menu-buttons {
  button {
    img,
    .color-indicator,
    .font-color-indicator {
      opacity: 0.3;
    }
  }

  .screenshare-buttons {
    .menu-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

div.sticker-select-text-channel {
  width: unset !important;

  &,
  &-feed {
    background: var(--primary-background, white);
  }
}
