@import './variables.less';

.cobrowser-container {
  box-sizing: border-box;
  padding: 10px;
  height: 100%;

  .underlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.1;
    border-radius: 10px;
    z-index: 1;

    &.cobrowser-focused {
      background-color: @focuscolor;
      opacity: 1;
    }
  }

  &:hover .underlay:not(.cobrowser-focused, .embedded-underlay) {
    opacity: 0.35;
  }

  .cobrowser-iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .cobrowser-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .iframe-bounding-box {
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    z-index: 2;
    position: relative;
  }
}
