.beacon-element {
  .beacon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
    position: absolute;
    outline: none;
    color: #fff;
    border-radius: 12px;
    background-color: #6b1be3;
    font-size: 1.2em;
    font-weight: 400;
    margin: 5px;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    input {
      width: calc(100% - 10px);
    }
  }
}

#beacon-set-destination-dialog {
  position: absolute;
  bottom: 60px;
  color: #fff;
  background-color: #000a;
  border-radius: 10px;
  font-size: 1em;
  padding: 10px;

  .set-button {
    outline: none;
    color: #fff;
    border-radius: 12px;
    height: 35px;
    padding: 10px 20px;
    background-color: #6b1be3;
    font-size: 1em;
    line-height: 5px;
    border: none;
    margin: 5px;
  }

  a {
    color: #ddd;
    font-size: 0.9em;
  }
}
