@import './variables.less';

.embedded-container {
  box-sizing: border-box;
  height: 100%;

  .embedded-iframe {
    background: @menuBackground;
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }

  .embedded-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.embedded-body {
  border: 10px solid rgba(107, 27, 227, 0.25);
  margin: 0;
}

.embedded-body-focused {
  border: 10px solid @menuBackgroundHover;
}
