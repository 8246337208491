@import './variables.less';
@import './mixins.less';

.uk-modal-body.welcome-modal {
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  width: 44%;
  min-width: 320px;
  color: var(--primary-foreground, white);
  background: var(--primary-background, linear-gradient(44.31deg, #1901aa 0%, #55017d 70%));
  background-attachment: fixed;

  #join-room-error,
  #join-room-content {
    width: 100%;
  }

  #device-scanning {
    display: block;
    font-size: 1em;
    padding: 5px 0;
  }

  .app-launch-gif {
    max-width: 400px;
    padding: 20px 0;
  }

  .app-launch-message {
    padding-bottom: 15px;
  }

  .join-room-button,
  .join-on-web-button {
    width: 50%;
    height: 50px;
    text-transform: capitalize;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    transition-duration: @transitionSpeed;
    transition-property: transform;
  }

  @media (max-width: 614px) {
    .join-room-button,
    .join-on-web-button {
      width: unset;
    }
  }

  .join-room-button:hover,
  .join-on-web-button:hover {
    -webkit-transform: @transitionScale;
    transform: @transitionScale;
  }

  #viewer-join-room-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .join-room-title {
      font-size: 48px;
    }

    .subtitle {
      margin-bottom: 100px;
      color: #ddd;
      font-size: 16px;
    }

    .steps {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 30px;

      .step {
        width: 180px;
        text-align: center;
        color: #ddd;
        opacity: @animationInitialOpacity;
        animation: joinRoomStep @animationDuration forwards;

        &:nth-child(1) {
          animation-delay: 1s;
        }

        &:nth-child(2) {
          animation-delay: 2.5s;
        }

        &:nth-child(3) {
          animation-delay: 4s;
        }

        .gif {
          height: 150px;
          margin-bottom: 20px;
        }
      }
    }

    .join-room-button {
      opacity: @animationInitialOpacity;
      animation: joinRoomStep @animationDuration 5s forwards;
    }
  }

  #public-join-room-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .join-room-title {
      margin-bottom: 2px;
      font-size: 40px;
      text-align: center;
    }

    @media (max-width: 500px) {
      .join-room-title {
        font-size: 28px;
      }
    }

    .subtitle {
      margin-bottom: 5%;
      margin-top: 2%;
      color: #ddd;
      font-size: 16px;
    }

    .steps {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 4%;

      .step {
        width: 180px;
        text-align: center;
        color: #ddd;
        opacity: @animationInitialOpacity;
        animation: joinRoomStep @animationDuration forwards;

        &:nth-child(1) {
          animation-delay: 1s;
        }

        &:nth-child(2) {
          animation-delay: 2.5s;
        }

        &:nth-child(3) {
          animation-delay: 4s;
        }

        .gif {
          height: 150px;
          margin-bottom: 20px;
        }

        .gif-red-flag {
          height: 120px;
          margin: 25px 0px;
        }
      }
    }

    .join-room-button {
      opacity: @animationInitialOpacity;
      animation: joinRoomStep @animationDuration 5s forwards;
      margin-top: 20px;
    }

    .public-room-identifier {
      color: white;
      background-color: @primaryColor;
      padding: 5px 8px;
      border-radius: 4px;
      margin-bottom: 2%;

      .public-room-identifier-icon {
        margin-right: 2px;
        height: 20px;
        width: auto;
        vertical-align: text-top;
      }
    }

    .public-room-community-title {
      font-weight: 600;
      color: white;
      text-align: center;
      margin-bottom: 0;
      margin-top: 2%;
    }

    .join-hr {
      border: 1px solid rgba(255, 255, 255, 0.2);
      margin: 2% 0px;
    }
  }

  #viewer-join-room-content,
  #public-join-room-content {
    overflow: hidden;

    .steps {
      transition: transform ease-in-out 0.35s;
    }

    .mobile-step-dots {
      margin: 15px auto 35px;
      width: 20%;
      display: none;
      justify-content: space-between;
      align-items: center;

      .mobile-step-dot {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: #ffffff;
        opacity: 0.2;
      }
    }

    @media (max-width: 720px) {
      .steps {
        width: 300%;

        &.first-step {
          transform: translateX(33.3%);
        }

        &.second-step {
          transform: translateX(0%);
        }

        &.third-step {
          transform: translateX(-33.3%);
        }
      }

      .mobile-step-dots {
        display: flex;

        &.first-step {
          .mobile-step-dot:nth-child(1) {
            opacity: 1;
          }
        }

        &.second-step {
          .mobile-step-dot:nth-child(2) {
            opacity: 1;
          }
        }

        &.third-step {
          .mobile-step-dot:nth-child(3) {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (min-width: 993px) and (max-width: 1499px) {
  .uk-modal-body.welcome-modal {
    width: 65%;
  }
}

@media (min-width: 501px) and (max-width: 992px) {
  .uk-modal-body.welcome-modal {
    width: 95%;
  }
}

@media (max-width: 500px) {
  .uk-modal-body.welcome-modal {
    width: 98%;
  }
}

@animationInitialOpacity: 0.3;
@animationDuration: 1.5s;

@keyframes joinRoomStep {
  0% {
    transform: scale(1);
    opacity: @animationInitialOpacity;
  }

  33% {
    transform: scale(1.1);
  }

  66% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.preview-camera-switch-container {
  display: inline-flex;
  line-height: 26px;
  margin-top: 2px;
  margin-bottom: 6px;
}

.preview-camera-devices {
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;

  .device-select {
    text-align: center;

    select {
      width: 372px;
      margin-left: 24px;
    }

    svg {
      width: 22px;

      .svg-color(var(--primary-foreground));
    }
  }
}

@media (max-width: 500px) {
  .preview-camera-devices {
    select {
      width: 87%;
    }
  }
}

.preview-room-title {
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#preview-camera-message {
  margin: auto;
  margin-right: 8px;
  font-size: 18px;
  font-weight: 300;
}

#preview-camera-error {
  background-color: white;
  border-radius: 10px;
  width: 80%;
  color: #a63;
  padding: 10px;
}

#preview-camera-off-button,
#preview-camera-on-button,
#preview-mic-off-button,
#preview-mic-on-button,
#preview-mic-levels {
  border-radius: 25px;
  width: 36px;
  height: 36px;
  background-size: 18px;
  cursor: pointer;
}
