@import './variables.less';

.upload-web-app-input {
  padding-left: 10px;
  font-size: 0.9em;
  width: 94%;
  height: 30px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(86, 75, 107, 0.5);
}

.upload-modal-icon {
  margin-left: 6px;
  margin-right: 6px;
  height: 34px;
  width: 34px;
}

.file-upload-box {
  background-color: #ededed;
  border-radius: 20px;
  color: black;
  width: 100%;
  padding: 6px;
  margin: -10px 20px -10px -6px;
  text-align: left;
}

.file-upload-button {
  border-radius: 5px;
  border: none;
  background: var(--secondary-background, @primaryColor);
  color: var(--secondary-foreground, white);
  height: 14px;
  line-height: 14px;
  width: 100%;
  display: block;
  padding: 10px 0;
  margin-top: 8px;
  text-align: center;
  cursor: pointer;
}

.file-upload-button-cancel {
  border-radius: 5px;
  border: none;
  background-color: #ededed;
  color: black;
  height: 14px;
  line-height: 14px;
  width: 100%;
  display: block;
  padding: 10px 0;
  margin-top: 8px;
  text-align: center;
  cursor: pointer;
}

.file-upload-dialog {
  min-width: 300px;
  border-radius: 20px;
  height: 225px;

  .file-upload-icons {
    display: flex;
    justify-content: space-around;
  }
}

.upload-default {
  color: var(--primary-foreground, #888);
}
