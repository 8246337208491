// brand colors
@midnight: #12002d;
@honey: #f6a730;
@lime: #39c360;
@aqua: #15d7d2;
@azure: #0081fe;
@herePurple: #6b1be3;
@lilac: #ba62ff;
@rose: #ff68c3;
@ruby: #f6335d;
@coral: #fc6957;
@discord: #5865f2;

// validation colors
@error: @ruby;
@success: @lime;

// text colors
@darkText: @midnight;

@menuBackground: @midnight;
@menuBackgroundHover: #6b1be3;
@menuOutline: #31007a;
@menuShadowColor: rgba(255, 255, 255, 0.1);

@topMenuHeight: 46px; // in room
@lobbyNavHeight: 85px;

@sidebarWidth: 61px;

@primaryColor: @herePurple;
@primaryColorRgb: 97, 44, 218;
@secondaryTextColor: #a1a1a1;
@newUpdateColor: #f6335d;
@offColor: #f6335d;

@darkTextColor: #585858;

@widgetBackgroundColor: @midnight;
@focuscolor: @midnight;

@transitionSpeed: 0.1s;
@transitionScale: scale(1.15);

@inactiveButton: #2a1942;

// screen sizes
@mobile-screen: ~'(max-width: 576px)';
@tablet-screen: ~'(max-width: 991px) and (min-width: 577px)';
@laptop-screen: ~'(min-width: 992px)';
