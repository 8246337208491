/* Warps */

.warp {
  transform: translateZ(0);
  text-align: center;

  .warp-waitlist-container {
    position: absolute;
    bottom: 80px;
    width: 100%;
    cursor: pointer;
    pointer-events: none;
    z-index: 1;

    .warp-waitlist {
      background-color: #fff;
      border-radius: 12px;
      color: #6b1be3;
      display: none;
      padding: 5px;
      border: 1.5px solid #6b1be3;
    }
  }

  .warp-container {
    height: 100%;
  }

  &:hover .warp-waitlist-animations {
    display: inline;
    animation: fadeIn 0.4s ease forwards;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes warpHover {
  0% {
    left: 27.5px;
    right: 27.5px;
    border-radius: 50%;
  }

  100% {
    left: 0;
    right: 0;
    border-radius: 10px;
  }
}

@keyframes warpUnhover {
  0% {
    left: 0;
    right: 0;
  }

  100% {
    left: 27.5px;
    right: 27.5px;
    border-radius: 50%;
  }
}

@keyframes warpGlow {
  0% {
    filter: none;
  }

  25% {
    filter: drop-shadow(0 0 20px rgba(164, 27, 134, 0.8));
  }

  50% {
    filter: drop-shadow(20px 20px 20px rgba(21, 66, 214, 0.8));
  }

  75% {
    filter: drop-shadow(-20px -20px 20px rgba(244, 19, 19, 0.8));
  }

  100% {
    filter: none;
  }
}

.warp-center {
  position: absolute;
  background-color: black;
  left: 27.5px;
  right: 27.5px;
  top: 0;
  bottom: 55px;
  animation: warpUnhover 0.5s forwards;
  border: 3px solid white;
  background-size: cover;
  cursor: pointer;
}

.warp:hover .warp-animations {
  animation: 0.4s warpHover forwards, 5s warpGlow infinite;
}

.warp-users {
  position: absolute;
  display: flex;
  cursor: pointer;
  pointer-events: none;
  align-items: center;
  bottom: calc(50% + 8px);
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 34px;
  width: fit-content;
}

/* Style when waiting for host acceptance */
.warp-pending {
  left: 0;
  right: 0;
  border-radius: 10px;
  transform: scale(1);
  animation: 5s warpGlow infinite;
}

.warp-title-container {
  position: absolute;
  bottom: 25px;
  width: 100%;
  text-align: center;

  .warp-title {
    display: inline;
    background-color: #444d;
    border-radius: 0.5em;
    line-height: 1em;
    white-space: nowrap;
    color: white;
    padding: 2px 5px;
    overflow: hidden;
  }
}

.warp-presence {
  display: inline-block;
  text-align: center;
  font-size: 1em;
  clip-path: circle(40%);
  width: 34px;
  height: 34px;
  bottom: 0;
  padding: 2px;
  color: white;
  pointer-events: none;
  cursor: pointer;
  background: rgb(39, 253, 29);
  background: linear-gradient(135deg, rgba(39, 253, 128, 1) 2%, rgba(64, 100, 255, 1) 75%);
}
