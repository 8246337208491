.color-dropdown {
  min-width: 255px !important;
  height: 260px;

  .text-channel-container & {
    background: var(--primary-background, white);
  }

  p {
    padding-top: 10px;
    font-size: 0.8em;
    line-height: 19px;
  }
}

.color-select-container {
  clear: both;
  min-width: 255px;
  display: flex;
  justify-content: space-between;
}

.color-select-with-alpha {
  height: 190px;
}

.color-picker {
  width: 150px;
}

.color-prefixes {
  width: 90px;
  display: inline-flex;
  flex-wrap: wrap;
}

.color-prefix {
  width: 25px;
  height: 25px;
  margin: 5px;
  border-radius: 5px;
  border: 3px solid white;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.color-prefix:hover {
  border-color: black;
}
