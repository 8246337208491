// TODO: Structure this file to nest rules in LESS style.
@import './variables.less';
@import './mixins.less';

.options-menu-option {
  width: 100%;
  text-align: left;

  &.hidden {
    display: none;
  }

  .right {
    position: absolute;
    right: 8%;
  }

  &.disabled {
    cursor: default;
  }
}

.camera-add-friend-menu-button {
  position: relative;

  img {
    position: absolute;
    top: -4px;
    width: 20px;
    max-width: none;

    &.request-sent-icon {
      top: 3px;
      left: 2px;
      width: 12px;
    }
  }

  &.request-sent .add-icon,
  &:not(.request-sent) .request-sent-icon {
    display: none;
  }

  &.request-sent {
    pointer-events: none;
  }
}

.duplicate-option,
.deck-option,
.undeck-option {
  .locked-text {
    display: none;
  }

  &.disabled {
    .locked-text {
      margin-left: 5px;
      display: inline-flex;
      align-items: center;
      font-size: 0.7rem;
      cursor: default;

      img {
        margin-left: 3px;
        margin-right: 5px;
        opacity: 0.6;
      }
    }
  }
}

.element-footer-options-translate {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;

  .element-footer-options {
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    height: 20px;
    line-height: 30px;
    z-index: 1000;
    padding: 0;
    margin: 0;
    transform-origin: bottom right;

    .element-option-button {
      &.unlock {
        display: none;
      }
    }

    &.locked {
      .element-option-button {
        display: none !important;
      }

      .element-option-button {
        &.unlock {
          display: block !important;
        }
      }
    }

    .rotating & {
      display: flex !important; // Mouse can leave it, but it still should be visible while rotating.
    }

    &.hidden {
      display: none !important; // Overriding inline styles appearing on hover
    }
  }

  .hidden {
    display: none !important;
  }
}

.boardElement:hover .element-footer-options {
  display: flex;
}

.element-option-item,
.element-option-button {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  margin: 0 2px;
}

@media @mobile-screen {
  @element-footer-button-size: 30px;

  .element-footer-options {
    height: @element-footer-button-size;
  }
  .element-option-item,
  .element-option-button {
    width: @element-footer-button-size;
    height: @element-footer-button-size;
    background-size: 18px 18px;
  }
}

.element-option-button {
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.element-option-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.camera-send-dm {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6031d6b3;
  border: 1px solid white;

  .send-message-icon {
    height: 9px;
    width: 9px;
  }

  &:hover {
    background-color: #6031d6;
  }
}

.element-options-menu {
  padding: 3px 10px;
  line-height: 25px;
}

.resize {
  cursor: move;
  background-image: url(/images/icons/resize.svg);
}

.rotate {
  background-image: url(/images/icons/rotate.svg);
}

.download {
  background-image: url(/images/icons/download.svg);
}

.unlock {
  background-image: url(/images/icons/lock-black.svg);
  background-size: 40%;
}

.rotate,
body.rotating-cursor * {
  cursor: url(/images/icons/rotate.svg) 10 10, alias;
}

.copy-button {
  background-image: url(/images/icons/copy.png);
  background-size: 10px 10px;
}

.element-overflow {
  background-image: url(/images/icons/more.svg);
}

.can-move {
  cursor: grab;
}

.pinnable {
  cursor: url(/images/icons/pin-cursor.svg), auto;

  &:hover {
    filter: drop-shadow(5px 5px 15px #612cda) drop-shadow(-5px -5px 15px rgb(189, 20, 255));
  }
}

.boardElement {
  &.deck-element {
    .element-container {
      filter: drop-shadow(5px 5px 15px #f00) drop-shadow(-5px -5px 15px #f00);
      cursor: copy;
    }

    .deck-option,
    .duplicate-option {
      display: none;
    }
  }

  &:not(.deck-element) {
    .undeck-option {
      display: none;
    }
  }
}

.unpinnable {
  cursor: url(/images/icons/pin-cursor-minus.svg), auto;
}

.ignore-pointer {
  pointer-events: none;
}

.boardElement {
  /* Center the element */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: filter ease-in-out 0.25s; // make glow effect on pinning appear/disappear smoothly

  .element-container {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;

    .rotatable-container.full-height {
      height: 100%;
    }
  }

  .containing-rectangle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale3d(1, 1, 1);
  }
}

.pin-to-element-toast {
  position: absolute;
  bottom: 60px;
  padding: 15px;
  background-color: #000a;
  border-radius: 10px;

  p {
    margin: 0;
    color: #fff;
    font-size: 1rem;

    &:not(:first-child) {
      margin-top: 5px;
    }
  }
}

.option-menu-extension {
  left: 185px;
  top: 0;
}

.element-container.ignore-pointer ~ .element-footer-options-translate {
  .interaction-option {
    display: none;
  }
}

.tag-user-button {
  font-size: 11px;
  color: black;
  line-height: 13px;
  text-align: center;
}

.tagging-container {
  position: absolute;
  top: 0;
  right: 0;
}
