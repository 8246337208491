@import './variables.less';

#screenshare-source-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 100px;
}

.screenshare-sources-body {
  height: 650px;
  border-radius: 20px;
  overflow: hidden;

  .screenshare-select-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 550px;

    .title {
      color: black;
      font-size: 1.1rem;
      font-weight: 500;
      margin-bottom: none;
    }

    .subtitle {
      color: #999;
      margin-bottom: 10px;
    }
  }

  .screenshare-option {
    width: 30%;
    border-radius: 10px;
    border: 2px solid transparent;

    .screenshare-thumb {
      height: 100px;
      margin: 0 auto;
      padding: 5px;
      display: block;
    }

    .screenshare-title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0 5px 5px 5px;
      font-size: 0.9em;
    }

    &:hover {
      background-color: #ccf;
    }
  }

  .selected {
    border: 2px solid @primaryColor;

    .screenshare-title {
      color: black;
    }
  }

  .screenshare-control-bottom {
    position: absolute;
    bottom: 0;
    height: 45px;
    left: 0;
    right: 0;
    box-shadow: 0 0 8px 5px #bbb;
    background-color: white;
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
    padding: 15px;
    align-items: center;

    button {
      border-radius: 8px;
      padding: 0 30px;
      height: 42px;
      border: none;

      &[disabled] {
        opacity: 0.5;
      }
    }

    .cancel-button {
      color: #444;
      background-color: #ccc;
    }

    .share-button {
      color: white;
      background-color: @primaryColor;
      line-height: 1.5em;
    }
  }
}
