// make Radix popovers z-index a bit lower (it's 2147483647 by default and breaks some UIKit-related layout)
div[data-radix-portal] {
  z-index: 500000 !important;
}

// Radix uses "will-change: transform;"-rule under the hood to improve performance
// But this rule breaks "backdrop-filter" (and I guess a couple more CSS props) so I decided to disable it
// If you'll face some weird Radix issues, it may be caused by the rule below so feel free to remove it, but keep in mind it'll disable DMWindow background blur
div[data-radix-popper-content-wrapper] {
  will-change: auto !important;
}
