@import './../../../styles/variables.less';

.switch {
  position: relative;
  height: 24px;
  width: 43px;
  min-width: 43px;
  display: inline-block;
  box-shadow: 0 4px 10px var(--primary-foreground-alpha-10, rgba(0, 0, 0, 0.1));
  border-radius: 30px;

  .slider {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background: var(--primary-background-alpha-70, rgba(180, 180, 180, 0.7));
    transition: 0.4s;
    border: 1px solid var(--primary-foreground-alpha-20, rgba(180, 180, 180, 0.2));

    &::before {
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      height: 20px;
      width: 20px;
      background: white;
      transition: 0.4s;

      .unthemed & {
        // unthemed class can be added to any container, so honestly don't know
        // how to increase specificity here, hence the use of !important.
        background: white !important;
      }

      body.themed & {
        background: var(--primary-foreground-alpha-30);
      }
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background: var(--secondary-background, @primaryColor);
      border-color: transparent;

      &::before {
        background: var(--secondary-foreground, white);
      }
    }
  }

  .slider-round {
    border-radius: 30px;

    &::before {
      border-radius: 50%;
    }
  }
}

input:checked + .slider {
  background: @primaryColor;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
