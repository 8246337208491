@import './variables.less';
@import './mixins.less';

.minimap-container {
  display: none;
  position: absolute;
  top: 64px;
  right: 10px;
  width: 152px;
  height: 115px;

  &.electron-windows {
    top: 79px;
  }

  #mini-map,
  #reset-position-button,
  #zoom-in,
  #zoom-out {
    background: var(--primary-background, @menuBackground);
    color: var(--primary-foreground, white);
    opacity: 0.7;
    .svg-color(var(--primary-foreground, white));

    &:hover {
      opacity: 1;
    }
  }

  #mini-map {
    z-index: 1000;
    position: absolute;
    width: 115px;
    height: 115px;
    border-radius: 10px;
    box-shadow: 0 0 6px @menuShadowColor;
    right: 0;
    top: 0;
    cursor: grab;
  }

  #mini-map:active {
      cursor: grabbing;
  }

  // TODO: Rewrite styling to CSS grids instead of absolute positions.
  #reset-position-button {
    z-index: 1000;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    box-shadow: 0 0 6px @menuShadowColor;
    right: 130px;
    overflow: hidden;
    top: 85px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  #zoom {
    z-index: 1000;
    position: absolute;
    width: 30px;
    height: 70px;
    border-radius: 15px;
    box-shadow: 0 0 6px @menuShadowColor;
    right: 130px;
    overflow: hidden;
    top: 0;
  }

  #zoom-in,
  #zoom-out {
    outline: none;
    border: none;
    font-size: 1.5em;
    text-align: center;
    width: 100%;
    height: 50%;
    cursor: pointer;
  }
}

@media (max-width: 613px) {
  .minimap-container {
    display: none !important;
  }
}

.map-controls-feed-open {
  margin-right: 240px;
  transition: margin-right 0.3s ease;
}

.map-controls-feed-closed {
  margin-right: 0;
  transition: margin-right 0.3s ease;
}

.minimap-feed-open {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.minimap-feed-closed {
  opacity: 1;
  transition: opacity 0.3s ease;
}
