@import '../../../../styles/variables.less';
@import '../../../../styles/mixins.less';

.room-details {
  .done-button {
    font-size: 1.2em;
    padding: 10px 20px;
    margin: auto;
    display: block;
  }

  .background-group {
    margin-top: 20px;

    .color-picker-container {
      position: relative;
      height: 250px;
      width: 300px;
      margin: auto;

      .color-select-container {
        margin: 0;
      }
    }

    .search-input-group {
      width: 100%;

      .copyright {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 12px;
        color: var(--primary-foreground, #888);

        .unsplash-link {
          text-decoration: underline;
          color: var(--primary-foreground, #555);
        }
      }

      .svg-color(var(--primary-foreground, black));
    }

    .search-button {
      background-color: #612ddb;
    }

    .uk-tab {
      margin-bottom: 0;
      margin-left: 0;

      li {
        padding-left: 0;
      }

      &::before {
        display: none;
      }

      a {
        color: var(--primary-foreground-70, rgba(153, 153, 153, 0.7));

        &:hover {
          color: var(--primary-foreground, #333);
        }
      }

      li.uk-active > a {
        padding-bottom: 4px;
        border-color: var(--primary-foreground, #1e87f0);
        color: var(--primary-foreground, #333);
        font-weight: bold;
      }

      .background-group-label {
        flex-grow: 1;
      }

      .upload-button-container {
        pointer-events: all;
        height: 32px;
        position: absolute;
        padding: 0 8px;
        right: 0;
        bottom: 5px;

        .upload-button {
          cursor: pointer;
          font-size: 13px;
        }

        .upload-icon {
          width: 16px;
          margin: 0 2px;
        }

        input {
          display: none;
        }
      }
    }

    .search-results {
      margin-top: 10px;
      padding: 0.4rem 0.8rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-height: calc(100vh - 264px);
      overflow-y: scroll;

      .image-container {
        position: relative;
        margin-bottom: 7px;
        flex: 0 0 32%;
        height: 120px;
        margin-top: 4px;
        cursor: pointer;
        transform: scale(1);
        transition: transform 0.3s;
        border-radius: 8px;
        overflow: hidden;

        .check-container {
          display: none;
        }

        &.selected {
          .check-container {
            @size: 30px;

            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: calc(50% - (@size / 2));
            left: calc(50% - (@size / 2));
            width: @size;
            height: @size;
            border: 2px solid white;
            border-radius: 50%;
            background-color: @primaryColor;
            pointer-events: none;

            .uk-icon {
              color: white;
            }
          }
        }

        .author-info {
          display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 24px;
          line-height: 21px;
          background: rgba(0, 0, 0, 0.3);
          padding-left: 10px;

          a {
            font-size: 12px;
            color: white;
          }
        }

        &:hover {
          transform: scale(1.05);

          .author-info {
            display: block;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

div.chat-background-picker-modal {
  padding: 0.8rem 1rem;
  border-radius: 1rem;
  background: var(--primary-background, white);

  .room-details {
    .search-results {
      max-height: 50vh;

      .image-container {
        height: 260px;
      }
    }
  }
}
