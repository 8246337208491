here-open-in-desktop-modal.uk-modal {
  padding: 0;
}

.modal-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(44.31deg, #55017d 0.28%, #1901aa 100.53%);
}

.modal-content {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 20px;

  .title {
    margin-top: 10px;
    color: #6b1be3;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 900;
    text-align: center;
  }

  .download-desktop-app-link {
    width: 100%;
    text-align: center;

    &.hidden {
      display: none;
    }
  }

  .open-in-web-button {
    margin-top: 20px;
    color: gray;
    font-family: 'Inter';
    font-size: 14px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }
  }
}
