.reaction {
  z-index: 2147483647;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform, opacity;
}

.reaction-up,
.reaction-down {
  position: absolute;
  width: 50px;
  height: 50px;
  font-size: 2em;
}

.reaction-up {
  animation: floatUp 4s linear;
}

.reaction-down {
  animation: floatDown 3s linear;
}

.reaction-scale-in {
  animation: scaleIn 0.5s linear;
  font-size: 2em;
  pointer-events: none;
}

.reaction-wave {
  animation: leftRight 1.5s alternate infinite ease-in-out;
}

.reaction-directed {
  animation: directed var(--duration) forwards linear;
  font-size: 2em;
  pointer-events: none;
}

@keyframes leftRight {
  100% {
    transform: translateX(30px);
  }
}

@keyframes floatUp {
  0% {
    transform: scale(0.7);
  }
  3% {
    transform: scale(1.4) translateY(-9px);
  }
  4% {
    transform: scale(0.9) translateY(-12px);
  }
  5% {
    transform: scale(1) translateY(-15px);
  }
  80% {
    opacity: 1;
    transform: scale(1) translateY(-140px);
  }
  100% {
    opacity: 0;
    transform: translateY(-200px);
  }
}

@keyframes floatDown {
  0% {
    transform: scale(0.7);
  }
  3% {
    transform: scale(1.4) translateY(9px);
  }
  4% {
    transform: scale(0.9) translateY(12px);
  }
  5% {
    transform: scale(1) translateY(15px);
  }
  80% {
    opacity: 1;
    transform: scale(1) translateY(140px);
  }
  100% {
    opacity: 0;
    transform: translateY(200px);
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(2);
    opacity: 0.5;
  }

  100% {
    transform: scale(40);
    opacity: 0;
  }
}

@keyframes directed {
  0% {
    transform: translate3d(var(--x-start), var(--y-start), 0) scale3d(1, 1, 1)
      rotate3d(0, 0, 1, var(--initial-rotation));
  }

  25% {
    transform: translate3d(var(--x-half), var(--y-half), 0) scale3d(3, 3, 1) rotate3d(0, 0, 1, var(--initial-rotation));
  }

  50% {
    transform: translate3d(var(--x-end), var(--y-end), 0) scale3d(1, 1, 1) rotate3d(0, 0, 1, var(--initial-rotation));
  }

  80% {
    transform: translate3d(var(--x-splash-end), var(--y-splash-end), 0)
      scale3d(var(--final-scale), var(--final-scale), 1) rotate3d(0, 0, 1, var(--final-rotation));
    opacity: 0.5;
  }

  100% {
    transform: translate3d(var(--x-splash-end), var(--y-splash-end), 0)
      scale3d(var(--final-scale), var(--final-scale), 1) rotate3d(0, 0, 1, var(--final-rotation));
    opacity: 0;
  }
}
