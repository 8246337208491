.app-admin-element {
  background-color: #eee;
  border-radius: 10px;

  .app-admin-contents {
    padding: 10px;

    .app-title {
      width: calc(100% - 20px);
      font-size: 1.2em;
      margin: 5px;
    }

    .admin-button {
      outline: none;
      color: #fff;
      border-radius: 12px;
      height: 50px;
      padding: 10px 20px;
      background-color: #6b1be3;
      font-size: 1.2em;
      line-height: 5px;
      border: none;
      margin: 5px;
    }
  }
}
