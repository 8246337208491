#main-effect-image {
  z-index: 9997;
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  display: none;
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.dreidel-effect {
  width: 45% !important;
  height: 45% !important;
  margin: auto;
  top: 25%;
  left: 28%;
}

.haunted-effect {
  margin: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: unset !important;
  z-index: 100000;
}

.trick-or-treat-effect {
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: unset !important;
  z-index: 100000;
}

.halloween-effect {
  margin: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: unset !important;
  z-index: 100000;
}

.explosion-effect {
  z-index: 9997;
  pointer-events: none;
  position: fixed;
  width: 40%;
  height: 40%;
  display: none;
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#explosion-effect-image-one {
  top: 30%;
  left: 30%;
}

#explosion-effect-image-two {
  top: 32%;
  left: 67%;
  width: 25%;
  height: 25%;
}

#explosion-effect-image-three {
  top: 46%;
  left: 9%;
  width: 25%;
  height: 25%;
}
