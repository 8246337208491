.fileCard {
  .rotatable-container {
    width: 100%;
    height: 100%;
  }

  .icon-background {
    height: 74%;
    width: 74%;
    background-size: contain;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon-background-outline {
    height: 74%;
    width: 74%;
    background-size: contain;
    top: 40%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }

  .file-icon {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
    filter: drop-shadow(0px 0px 5px rgba(18, 0, 45, 0.5));
  }

  .audio-file {
    height: 55%;
    width: 55%;
    z-index: 1;
    transform: translate(-50%, -65%);
  }

  .half-opacity {
    filter: opacity(50%);
  }

  .video-file {
    height: 40%;
    width: 40%;
    z-index: 1;
  }

  .text-file {
    height: 40%;
    width: 40%;
    z-index: 1;
  }

  .spreadsheet-file {
    height: 38%;
    width: 38%;
    z-index: 1;
  }

  .presentation-file {
    height: 44%;
    width: 44%;
    z-index: 1;
  }

  .unknown-file {
    height: 42%;
    width: 42%;
    z-index: 1;
  }

  .filename-area {
    position: fixed;
    text-align: center;
    width: 100%;
    height: 14%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1000px;
    z-index: 2;
    bottom: 8%;
  }

  .filename {
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
    white-space: nowrap;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .filename-input {
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    white-space: nowrap;
    height: auto;
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
    border: 0px;
    text-decoration: none;
    background-color: transparent;
    font-size: 1.05em;
    text-align: center;
  }

  .filename-input:focus {
    outline: none;
  }

  .input-area {
    display: none;
    bottom: 8%;
    position: fixed;
    text-align: center;
    width: 100%;
    height: 14%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1000px;
    z-index: 2;
    left: 1px;
    border: 1px solid white;
  }
}
