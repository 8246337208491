@import './variables.less';
@import './mixins.less';

///// SHARED STOPWATCH & TIMER STYLES /////

.timer-content {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  background: var(--primary-background, @menuBackground);
}

.timer-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.15);
}

.timer-header {
  height: 74px;
  display: flex;
  align-items: center;
  padding-left: 23px;
  color: var(--primary-foreground, white);
  background: var(--primary-background, @menuBackground);

  svg {
    height: 21px;
    margin-right: 15px;
  }

  .header-icon {
    .svg-color(var(--primary-foreground, white));
  }
}

.timer-header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.timer-cell {
  text-align: center;
  line-height: normal;
  display: table-row;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

/////////////////////////////////////

.timer-bg {
  text-align: center;
  width: 260px;
  height: 175px;
  border-radius: 20px;
  border: 1px solid #fafbfc;
  background-color: #201b4b;
}

.timer-clock {
  color: var(--primary-foreground, darkgray);
  line-height: 1;
  margin: auto;
  margin-top: 10px;
}

.timer-stopwatch-time {
  font-size: 2em;
  height: auto;
  color: var(--primary-foreground, white);
  margin: auto;
  margin-top: 25px;
  font-weight: thin;
  font-family: 'Courier Prime', monospace;
}

.timer-ui-button {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  z-index: -1000;
  width: auto;
  height: 10%;
  background-color: transparent;
  text-decoration: none;
  outline: 0;
  border: none;
  cursor: pointer;

  svg {
    width: 30px;

    .svg-color(var(--primary-foreground, white));
  }
}
