/* Editing style */
.textCard .CodeMirror,
.textCard.text-editing {
  background-color: inherit;
  //color: #eaeaea;
}

.textCard .CodeMirror-cursor {
  // border-color: white;
}

.textCard .CodeMirror-selected {
  background-color: #778;
}

/* TODO this doesn't seem to work. */
.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background: #778;
}

.textCard {
  color: white;

  h1,
  h2,
  h3,
  h4 {
    color: #ddd;
    margin: 10px 0 10px 0;
  }
}

.text-container,
.text-editor {
  position: relative;
  height: calc(100% - 30px);
  overflow: hidden;
  padding: 15px;
  border-radius: 10px;
  background-color: rgba(45, 45, 45, 0.8);
}

.text-editor-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}

.text-editor {
  display: none;
}

.text-container {
  padding: 15px;

  * {
    font-family: inherit;
  }

  pre {
    background-color: #0000;
    color: inherit;
    border: none;
    font-size: 1rem;
    line-height: 1rem;
  }

  div,
  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.1;
  }
}

.text-container:hover {
  overflow-y: scroll;
}

.firepad-done-editing-button {
  position: absolute;
  right: 8px;
  bottom: 10px;
  border: none;
  border-radius: 17.5px;
  width: 35px;
  height: 35px;
  font-size: 1.5em;
  background-image: url(/images/icons/check-white.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
  background-color: #2d2d2d;
  z-index: 1000;
  outline: none;
}

.text-done-editing-button {
  position: absolute;
  right: 10px;
  bottom: 28px;
  border: none;
  border-radius: 17.5px;
  width: 35px;
  height: 35px;
  font-size: 1.5em;
  background-image: url(/images/icons/check-white.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
  background-color: #2d2d2d;
  outline: none;
}

.text-done-editing-button:hover,
.firepad-done-editing-button:hover {
  border: none;
  background-color: #56a;
}
