.broadcast-leaving-dialog {
  text-align: center;
  border-radius: 16px;
  padding: 30px 0;

  .title {
    font-weight: bold;
    margin: 0 auto;
    color: black;
  }

  .text {
    margin: 16px auto;
    width: 70%;
  }

  .buttons {
    .uk-button {
      border-radius: 12px;
      text-transform: none;

      &.uk-button-default {
        background-color: #ededed;
        margin-right: 8px;
        border: none;
      }
    }
  }
}
