.help-tab {
  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tab-content-title-mobile {
      display: none;
    }

    .close-room-settings-button {
      display: none;
      font-size: 18px;
      background: inherit;
      border: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    @media (max-width: 613px) {
      .tab-content-title {
        padding-left: 10px;
      }

      .tab-content-title {
        display: none;
      }

      .tab-content-title-mobile {
        padding: 0;
        display: block;
      }

      .tab-content-subtitle-mobile {
        display: block;
      }

      .close-room-settings-button {
        display: block;
      }
    }
  }

  .tab-content-subtitle-mobile {
    padding-left: 10px;
    margin-top: 25px !important;
    display: none;
  }

  @media (max-width: 613px) {
    .tab-content-subtitle-mobile {
      display: block;
      font-size: 15px;
    }
  }

  .notifications-block {
    padding: 20px 10px;
    display: none;
    background-color: #f0f0f0;
    border-radius: 10px;
  }

  @media (max-width: 613px) {
    .notifications-block {
      display: block;
    }
  }
}
