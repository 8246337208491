@import '../variables.less';

.blur {
  filter: blur(5px);
}

.underlay.embedded-underlay {
  opacity: 1;
  background: none;

  &.overlay {
    z-index: 3;
    backdrop-filter: blur(1px);
  }

  .underlay-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      width: 40%;
      height: 40%;
      z-index: 2;
      filter: drop-shadow(0px 0px 5px rgba(18, 0, 45, 0.5));
    }

    .title {
      color: white;
      font-size: 20px;
      z-index: 2;
      margin-top: 6px;
      margin-left: 25px;
      margin-right: 25px;
      filter: drop-shadow(0px 0px 5px rgba(18, 0, 45, 0.5));
    }

    .underlay-background {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;

      &.trello-background {
        background-color: #006cb3;
      }

      &.figma-background {
        background: linear-gradient(
          180deg,
          #262626 0%,
          #fe3902 0.01%,
          #fc6451 28.13%,
          #b228ff 57.29%,
          #00baff 77.6%,
          #00d880 100%
        );
      }

      &.airtable-background {
        background: linear-gradient(180deg, #ffb500 0%, #ff0054 50%, #00bdff 100%);
      }

      &.pdf-background {
        background: #ff0000;
      }

      &.google-doc-background {
        background-color: #3d7afc;
      }

      &.google-sheet-background {
        background-color: #00b04c;
      }

      .cobrowser-focused & {
        background-color: @focuscolor;
        opacity: 1 !important; // Overriding inline styles added in js.
      }
    }
  }
}
