.chat-tab {
  h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    color: var(--primary-foreground, black);
  }

  hr {
    margin: 10px 0;
    border-top-color: var(--primary-foreground, #e5e5e5);
  }

  button {
    padding: 0 15px;
    height: 40px;
  }

  .option-section {
    padding: 14px;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }

  .subtitle {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--primary-foreground, #868686);
    margin: 6px 0 0 0;
  }

  .feed-background-preview-section {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;

    .feed-background-preview {
      position: relative;
      width: 120px;
      height: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      margin-right: 25px;
    }
  }

  .customize-chat-sounds-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;

    img {
      height: 15px;
      width: 19px;
    }
  }
}
