@import './variables.less';

.wtv-card {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  text-align: center;
  font-weight: normal;
  color: white;

  .wtv-restart {
    float: right;
    color: white !important;
    background: none !important;
    width: 5%;
    font-size: 1em;
    top: 0;
    position: absolute;
    right: 1%;
    margin: auto;
    margin-top: 5px;
  }

  .wtv-bg {
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(#5818b7, #8b128f);
    overflow: hidden;
  }

  .game-content {
    width: 100% !important;
    height: 100% !important;
    color: white !important;
  }

  .game-title {
    padding-top: 30px;
    font-weight: bold;
    color: white;
    line-height: 1;
    margin: auto;
  }

  .game-description {
    font-size: 8pt;
    margin: auto;
    color: @secondaryTextColor;
    margin-top: 10px;
  }

  .start-playing-button {
    border-radius: 12px;
    height: 50px;
    padding: 10px 20px;
    background-color: #6b1be3;
    font-size: 1.2em;
    line-height: 5px;
    margin-top: 18px;
    border: none;
    color: white;
  }

  h5 {
    color: white;
  }

  .sub-header {
    color: white;
    font-weight: bold;
    font-size: 7pt;
  }

  .correct {
    margin: auto;
    margin-top: 15px;
    z-index: -1000;
    width: 170px;
    border-radius: 12px;
    height: 50px;
    color: white;
    background-color: green;
    border: 1px solid white;
  }

  .pass {
    margin: auto;
    margin-top: 10px;
    z-index: -1000;
    width: 170px;
    border-radius: 12px;
    height: 50px;
    color: white;
    background-color: red;
    border: 1px solid white;
  }

  .result-score {
    padding-top: 20px;
    color: white;
    font-weight: bold;
    margin: 0;
    padding-bottom: 10px;
    place-content: center;
  }

  .full-width {
    width: 100%;
  }

  .full-height {
    height: 100%;
  }

  .wtv-result-correct {
    color: #0f0 !important;
    line-height: 1.3;
    margin: 0;
    font-size: 10pt;
  }

  .wtv-result-incorrect {
    color: darkgray !important;
    line-height: 1.3;
    margin: 0;
    font-size: 10pt;
  }

  .guess-tooltip {
    line-height: 0;
    color: white;
    margin-top: 15%;
  }

  .guesser-text {
    font-size: 0.9em;
    color: white;
    bottom: 1%;
    left: 4%;
    position: absolute;
    margin: auto;
    margin-bottom: 5px;
  }

  .wtv-status {
    font-size: 2em;
    color: white;
    place-content: center;
  }

  .voice-prompt {
    color: white;
    margin: 0;
    padding-top: 0;
    font-weight: bold;
    place-content: center;
  }

  .game-title-div {
    width: 65%;
    margin: auto;
    margin-top: 11%;
    background-color: black;
    border-radius: 18px;
    padding: 26px;
    border: 1px solid white;
    overflow: auto;
    text-align: center;
  }

  .in-game {
    margin-top: 10%;
  }
}
