.poker-element {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  text-align: center;
  font-weight: normal;
  background-image: url('../images/games/poker.jpg');
  background-size: cover;
  color: var(--primary-foreground, white);
  box-sizing: border-box;

  &,
  h1,
  h3 {
    color: var(--primary-foreground, white);
    margin: 0;
  }

  h1 {
    font-size: 2.1em;
  }

  h3 {
    font-size: 1.3em;
  }

  button:not(.options-menu-option) {
    height: 50px;
    padding: 10px 20px;
    font-size: 1.2em;
    line-height: 5px;
    margin: 20px auto 0;
  }

  p {
    font-size: 0.9em;
  }

  .game-title-screen-div {
    width: 37%;
    margin: auto;
    margin-top: 13%;
    background: var(--primary-background, black);
    border-radius: 18px;
    padding: 26px;
    border: 1px solid white;
  }
}
