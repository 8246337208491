@import './variables.less';
@import './mixins.less';

.notifications-container {
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999;

  .notification {
    display: flex;
    flex-direction: row;
    background: var(--primary-background, white);
    width: 500px;
    border-radius: 16px;
    overflow: hidden;
    margin: 0 auto 16px;

    .left-area {
      width: 80px;
      flex-shrink: 0;
      background: var(--secondary-background, @primaryColor);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 18px;

      svg {
        .svg-color(var(--secondary-foreground, white));
      }
    }

    .message {
      padding: 16px 32px;
      font-size: 14px;
      color: var(--primary-foreground, black);

      .link {
        cursor: pointer;
        text-decoration: underline;
        color: var(--primary-foreground, #666);
      }
    }

    .close-button {
      cursor: pointer;
      color: var(--secondary-foreground, @primaryColor);
      margin: 10px 0;
    }

    .accept-button,
    .ignore-button {
      cursor: pointer;
      border: none;
      outline: none;
    }

    .accept-button {
      background: var(--secondary-background, @primaryColor);
      color: var(--secondary-foreground, white);
      padding: 8px 16px;
      margin: 0 16px;
      border-radius: 8px;
    }

    .ignore-button {
      background-color: transparent;
      color: #666;
    }

    &.viewer-demotion-notification {
      .left-area {
        background: var(--secondary-background, #e7e5ea);

        .alert-icon {
          width: 31px;
          height: 31px;
        }
      }
    }
  }
}

.notifications-container-bottom {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999;
}

.modal-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 1000000;
}
