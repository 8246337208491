@red: #e6465e;

.on-air {
  width: 80px;
  text-align: center;

  .off-button-container {
    .off-button {
      background-color: @red;
      color: white;
      border: none;
      padding: 4px 12px;
      border-radius: 4px;
      font-size: 14px;
      outline: none;
      cursor: pointer;
    }
  }

  .on-air-caption {
    text-transform: uppercase;
    color: var(--primary-foreground, white);
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .dot {
      @size: 6px;

      display: inline-block;
      height: @size;
      width: @size;
      border-radius: 50%;
      background-color: @red;
    }
  }
}
