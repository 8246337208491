@import './variables.less';

.progress-bar-section {
  input[type='range'] {
    padding: 19px 0px;
    background-color: transparent;

    /*removes default webkit styles*/
    -webkit-appearance: none;

    /*fix for FF unable to apply focus style bug */
    /*border: 1px solid white;*/

    /*required for proper track sizing in FF*/
    width: 100%;

    position: relative;
    top: 28px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 80px;
    height: 6px;
    background: #ffffff99;
    border: none;
    border-radius: 20px;
  }

  input[type='range']::-webkit-slider-thumb {
    border: none;
    border-radius: 50%;
    background: white;
    width: 1.5em;
    height: 1.5em;
    box-shadow: 0 0 2px black;
    -webkit-appearance: none;
    margin-top: -6px;
  }

  input[type='range']::-webkit-slider-thumb:active {
    background: white;
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }

  input[type='range']::-moz-range-track {
    width: 80px;
    height: 2px;
    background: #ccc;
    border: none;
    /* border-radius: 3px; */
  }

  input[type='range']::-moz-range-thumb {
    border: none;
    border-radius: 50%;
    background: white;
    width: 2.2em;
    height: 2.2em;
    box-shadow: 0 0 2px black;
  }

  /*hide the outline behind the border*/
  input[type='range']:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
  }

  input[type='range']::-ms-track {
    width: 80px;
    height: 2px;

    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;

    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
  }

  input[type='range']::-ms-fill-lower {
    background: #777;
    border-radius: 20px;
  }

  input[type='range']::-ms-fill-upper {
    background: #ccc;
    border-radius: 20px;
  }

  input[type='range']::-ms-thumb {
    border: none;
    border-radius: 50%;
    background: white;
    width: 2.2em;
    height: 2.2em;
    box-shadow: 0 0 2px black;
  }

  input[type='range']:focus::-ms-fill-lower {
    background: #888;
  }

  input[type='range']:focus::-ms-fill-upper {
    background: #ccc;
  }
}
