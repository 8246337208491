@import './variables.less';

.skribblio-element {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  text-align: center;
  font-weight: normal;
  background-image: url('../images/games/skribblio.png');
  background-repeat: repeat;
  overflow: hidden;

  h1,
  h3 {
    color: var(--primary-foreground, white);
    margin: 0;
  }

  h1 {
    font-size: 2.1em;
  }

  h3 {
    font-size: 1.3em;
  }

  .title-logo {
    margin-top: 10%;
  }

  .title-logo-small {
    height: 80px;
    margin-top: 30px;
  }

  button:not(.options-menu-option) {
    height: 50px;
    padding: 10px 20px;
    font-size: 1.2em;
    line-height: 5px;
    margin: 0 auto;
  }

  p {
    font-size: 1.1em;
    color: var(--primary-foreground, white);
  }

  a,
  a:hover {
    color: var(--primary-foreground, @primaryColor);
    text-decoration: underline;
  }

  .game-title-screen-div {
    width: fit-content;
    margin: auto;
    background: var(--primary-background, black);
    border-radius: 18px;
    padding: 26px;
    border: 1px solid white;
    resize: both;
    overflow: auto;
    color: white;
  }
}

.skribblio-background {
  height: calc(100% - 185px) !important;
  background-image: url('../images/games/skribblio.png');
  background-repeat: repeat;
  border-radius: 0px !important;
}

.skribblio-background-full {
  height: 100% !important;
  background-image: url('../images/games/skribblio.png');
  background-repeat: repeat;
}

.player-gif {
  height: calc(100% - 150px);
  margin-top: -30px;
}

.host-gif {
  height: 100%;
}

.player-gif-div {
  width: 100%;
  position: fixed;
  text-align: center;
  margin: auto;
  height: calc(100% - 185px) !important;
  position: absolute;
  overflow: hidden;
  z-index: 4;
}

.player-gif-div-background {
  background-color: rgba(107, 27, 227, 0.8);
  height: 100%;
  width: 100%;
  z-index: 1;
}

.skribbl-close-button {
  position: relative;
  right: 0;
  top: -38%;
  color: white !important;
  z-index: 5;
}
