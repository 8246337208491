.users-count-container {
  margin: 0 12px;

  img {
    height: 16px;
    position: relative;
    top: -2px;
  }

  .users-count {
    color: white;

    &.capacity-reached {
      color: #f00;
    }
  }
}

.uk-tooltip.uk-active.users-count-tooltip {
  @height: 50px;

  display: flex;
  align-items: center;
  color: black;
  background-color: white;
  height: 50px;
  width: fit-content;
  border-radius: @height / 2;
  padding: 10px;
}
