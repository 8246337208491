@import './mixins.less';

#user-profile-ghost-anchor {
  position: absolute;
}

#user-profile {
  position: absolute;
  padding: 0;
  overflow: auto;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  z-index: 100001;

  overflow-x: hidden;
  .custom-scrollbar(transparent, 0);
}

#user-profile-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  z-index: 100001;
}

#user-profile-more-menu-ghost-anchor {
  position: absolute;
}

#user-profile-more-menu {
  position: absolute;
  padding: 0;
  overflow: hidden;
}

#picker-modal {
  position: fixed;
  z-index: 1000000;
}
