.electron-upsell {
  position: fixed;
  top: 15px;
  right: 0;
  left: 0;
  margin: auto;
  padding: 10px;
  width: fit-content;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #12002d;
  border-radius: 16px;

  &.hidden {
    display: none;
  }

  .title {
    margin: 0;
    padding: 0 17px;
    color: #ffffff;
    font-family: 'Inter';
    font-weight: 500;
  }

  .download-link {
    padding: 11px 13px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 100px;
    cursor: pointer;

    .icons {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
      opacity: 0.9;

      img + img {
        margin-left: 9px;
      }
    }

    .text {
      margin-left: 9px;
      color: #12002d;
      font-weight: 500;
    }

    &:hover {
      .icons {
        opacity: 1;
      }

      .text {
        text-decoration: underline;
      }
    }
  }

  .windows-icon,
  .mac-icon {
    &.hidden {
      display: none;
    }
  }
}

@media (max-width: 962px) {
  .electron-upsell {
    display: none;
  }
}
