/*
  Currently we have the following theming CSS variables:
  • --primary-font - used for room name only.
  • --primary-foreground and --primary-background - main background and text colors.
  • --secondary-foreground and --secondary-background - usually used for buttons and hover effects.
  Each of aforementioned variables has 9 modifications for using with alpha channels,
  e.g. --primary-background-alpha-30 means it's --primary-background with opacity 0.3.
  So using it with default value would look like this:
  var(--primary-background-alpha-30, rgba(0, 0, 0, 0.3))

  Also if a theme is applied, body element has 'themed` class.
  Default theme (in other words, no theme) means that all these variables are unset,
  also 'themed' class is removed from the body.
*/

// Easily remove theming from particular container (e.g. from DMs).
.unthemed {
  --primary-foreground: initial;
  --primary-foreground-alpha-10: initial;
  --primary-foreground-alpha-20: initial;
  --primary-foreground-alpha-30: initial;
  --primary-foreground-alpha-40: initial;
  --primary-foreground-alpha-50: initial;
  --primary-foreground-alpha-60: initial;
  --primary-foreground-alpha-70: initial;
  --primary-foreground-alpha-80: initial;
  --primary-foreground-alpha-90: initial;
  --primary-background: initial;
  --primary-background-alpha-10: initial;
  --primary-background-alpha-20: initial;
  --primary-background-alpha-30: initial;
  --primary-background-alpha-40: initial;
  --primary-background-alpha-50: initial;
  --primary-background-alpha-60: initial;
  --primary-background-alpha-70: initial;
  --primary-background-alpha-80: initial;
  --primary-background-alpha-90: initial;
  // Secondary color is mainly used for buttons, but not in all cases,
  // so gonna use this naming to avoid confusion.
  --secondary-foreground: initial;
  --secondary-foreground-alpha-10: initial;
  --secondary-foreground-alpha-20: initial;
  --secondary-foreground-alpha-30: initial;
  --secondary-foreground-alpha-40: initial;
  --secondary-foreground-alpha-50: initial;
  --secondary-foreground-alpha-60: initial;
  --secondary-foreground-alpha-70: initial;
  --secondary-foreground-alpha-80: initial;
  --secondary-foreground-alpha-90: initial;
  --secondary-background: initial;
  --secondary-background-alpha-10: initial;
  --secondary-background-alpha-20: initial;
  --secondary-background-alpha-30: initial;
  --secondary-background-alpha-40: initial;
  --secondary-background-alpha-50: initial;
  --secondary-background-alpha-60: initial;
  --secondary-background-alpha-70: initial;
  --secondary-background-alpha-80: initial;
  --secondary-background-alpha-90: initial;
  --primary-font: initial;
}

.themed-font {
  font-family: var(--primary-font, Inter, sans-serif);

  body:not(.has-themed-font) & {
    font-weight: bold; // Making bold default font
  }
}

.themed-shadow {
  body.themed & {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  }

  body.themed .unthemed &,
  body:not(.themed) & {
    box-shadow: none;
  }
}

.themed-select {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  background: var(--primary-background) border-box;
  color: var(--primary-foreground);
  border: none;
  border-right: 12px solid transparent; // Workaround for the arrow
  padding: 12px;
  border-radius: 10px;
  outline: 1px solid var(--primary-foreground-alpha-20, rgba(0, 0, 0, 0.2));

  &:hover {
    outline: 1px solid var(--primary-foreground-alpha-40, rgba(0, 0, 0, 0.4));
  }
}

.themed-input {
  &&& {
    border-radius: 10px;
  }

  body.themed & {
    outline: none;
    color: var(--primary-foreground, #666);
    box-shadow: 0 4px 10px 0 var(--primary-foreground-alpha-10);
    border: 1px solid var(--primary-foreground-alpha-20, #e5e5e5);
    background: var(--primary-background-alpha-70);

    &:hover {
      box-shadow: 0 2px 5px 0 var(--primary-foreground-alpha-30, #aaa);
      border: 1px solid var(--primary-foreground-alpha-40, #aaa);
    }

    &:focus,
    &:focus-visible {
      box-shadow: none;
      border: 2px solid var(--secondary-background, #999);
      background: var(--primary-background);
    }

    &:disabled {
      background: var(--primary-foreground-alpha-10);
      box-shadow: none;
      color: var(--primary-foreground-alpha-50);
    }

    &::placeholder {
      color: var(--primary-foreground-alpha-50);
    }
  }
}

.themed-slider {
  body.themed & {
    @trackHeight: 6px;
    @thumbHeight: 14px;

    appearance: none;
    background: transparent;

    &::-webkit-slider-thumb {
      appearance: none;
      background: var(--secondary-background);
      border: 2px solid var(--primary-foreground);
      height: @thumbHeight;
      width: @thumbHeight;
      border-radius: 50%;
      margin-top: (@trackHeight - @thumbHeight) / 2;
    }

    &::-webkit-slider-runnable-track {
      background: var(--secondary-background);
      height: @trackHeight;
      border-radius: @trackHeight / 2;
    }
  }
}
