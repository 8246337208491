.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  animation-delay: 1s;
  z-index: 1000;

  .dot {
    position: relative;
    width: 0.5em;
    height: 0.5em;
    margin: 0.4em 0.2em;
    border-radius: 50%;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: wave 2s ease-out infinite;
    }

    &:nth-of-type(1) {
      background: #1030a0;

      &::before {
        animation-delay: 0.2s;
      }
    }

    &:nth-of-type(2) {
      background: #3040a0;

      &::before {
        animation-delay: 0.4s;
      }
    }

    &:nth-of-type(3) {
      background: #5060a0;

      &::before {
        animation-delay: 0.6s;
      }
    }

    &:nth-of-type(4) {
      background: #7070a0;

      &::before {
        animation-delay: 0.8s;
      }
    }

    &:nth-of-type(5) {
      background: #9080a0;

      &::before {
        animation-delay: 1s;
      }
    }

    &:nth-of-type(6) {
      background: #a080a0;

      &::before {
        animation-delay: 1.2s;
      }
    }
  }
}

@keyframes wave {
  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}
