.onboarding-disabled {
  pointer-events: none !important;
  opacity: 0.5;
}

.hubs-onboarding-disabled {
  pointer-events: none !important;
}

.highlight-round-pulse,
.highlight-default-pulse,
.highlight-nux-group-room {
  position: relative;
  display: inline-block;
}

.highlight-pulse-mixin (@width, @height, @border-radius) {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: @width;
  height: @height;
  z-index: -1;
  border-radius: @border-radius;
  opacity: 0.7;
  transform: scale(0);
  background-color: white;
}

.highlight-round-pulse::before,
.highlight-round-pulse::after {
  .highlight-pulse-mixin(40px, 40px, 100%);

  animation: highlight-pulse-animation 2s infinite;
}

.highlight-round-pulse-front::before,
.highlight-round-pulse-front::after {
  z-index: 1000;
}

.highlight-default-pulse::before,
.highlight-default-pulse::after {
  .highlight-pulse-mixin(100%, 100%, inherit);

  animation: highlight-tiny-pulse-animation 2s infinite;
}

.highlight-nux-group-room::before,
.highlight-nux-group-room::after {
  .highlight-pulse-mixin(100%, 100%, inherit);

  animation: highlight-nux-group-room-animation 2s infinite;
}

.highlight-round-pulse::after,
.highlight-default-pulse::after,
.highlight-nux-group-room::after {
  animation-delay: 1s;
}

@keyframes highlight-pulse-animation {
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes highlight-tiny-pulse-animation {
  90% {
    opacity: 0.25;
  }

  100% {
    transform: scale(1.11, 1.4);
    opacity: 0;
  }
}

@keyframes highlight-nux-group-room-animation {
  90% {
    opacity: 0.25;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
