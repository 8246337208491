@import '../styles/variables.less';

@font-face {
  font-family: Digital;
  src: url(/fonts/digital/digital.ttf);
}

.presentation-mode-button {
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 0;
  padding: 13px;
  z-index: 1;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 20px;
  background-color: #39c360;
  cursor: pointer;

  .text {
    margin-left: 8px;
    margin-right: 16px;
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
}

.presentation-mode-controls {
  display: none;

  &.visible {
    display: flex;
  }
}

.camera-container {
  padding: 0;
  width: 100%;
  height: 100%;
  // transform must happen in the outer container of video in order to
  // avoid clipping bugs
  // translateZ moves clipping to GPU

  img.no-signal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000000;
    display: none;
  }

  &.screenshare {
    &:hover {
      .presentation-mode-controls {
        display: flex;
      }
    }
  }

  .video-container {
    width: 100%;
    height: 100%;

    video {
      max-width: 100%;
      max-height: 100%;
    }

    .camera-muted-icon {
      position: absolute;
      left: 5%;
      bottom: 5%;
      z-index: 1;
      height: 14%;
      width: 14%;
      border: none;
      background-color: #f6335d;
      background-image: url('../images/icons/mic-off-white.svg');
      background-size: 60%;
      background-position: 50%;
      background-repeat: no-repeat;
      clip-path: circle(50% at 50% 50%);

      &.camera-muted-button {
        cursor: pointer;

        &:hover {
          background-color: #ff556f;
        }
      }

      &.hidden {
        display: none;
      }
    }
  }

  .camera {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    // background-color: rgb(40, 80, 180);
    max-width: 100%;
    max-height: 100%;
  }

  .mirror {
    transform: scaleX(-1) translateZ(0);
  }

  video,
  .video-snapshot {
    position: relative;
    object-fit: cover;
    object-position: center;
    min-width: 100%;
    min-height: 100%;
  }

  here-avatar {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 3em;
  }
}

.screenshare-fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;

  .screenshare-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 13%;
    background: linear-gradient(180deg, rgba(18, 0, 45, 0) 0%, rgba(18, 0, 45, 0.08) 32.81%, rgba(18, 0, 45, 0.4) 100%);
    display: none;
    z-index: 1;
  }

  .screenshare-controls-bar {
    position: absolute;
    bottom: 8px;
    left: 12px;
    transform-origin: bottom left;
    z-index: 1;
    display: none;
    box-sizing: border-box;
    align-items: center;

    .volume-icon {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 14px;
        filter: invert(1);

        &[src$='off.svg'] {
          width: 10px;
        }
      }

      .here-audio-control-wrapper {
        position: absolute;
        bottom: -8px;
        left: 0;
        z-index: 1;
        display: none;
        transform: scale(0.7);
        transform-origin: bottom left;
      }

      &:hover {
        .here-audio-control-wrapper {
          display: block;
        }
      }
    }
  }

  &:hover {
    .screenshare-controls-bar,
    .screenshare-overlay {
      display: block;
    }

    .screenshare-controls-bar.hidden {
      display: none;
    }
  }

  &:fullscreen:hover {
    .screenshare-controls-bar,
    .screenshare-overlay {
      display: none;
    }
  }

  &::backdrop {
    background-color: #000000a0;
    backdrop-filter: blur(6px);
  }

  .animation {
    height: unset !important;
  }

  .camera-name-container {
    position: absolute;
    top: -30px;
    max-width: 600px;
    bottom: unset;
    justify-content: flex-start;

    .camera-name {
      margin: 0 auto;
    }
  }
}

.presentation-mode-dropdown {
  padding: 19px 17px;
  background-color: white;
  border-radius: 10px;

  .uk-dropdown-nav {
    padding-left: 20px;
  }

  .presentation-mode-list-item {
    &.selected {
      list-style-image: url(../images/icons/check-main.svg);
    }
  }
}

.camera-name-container {
  position: absolute;
  max-width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;
  gap: 2px;
  height: 64px;

  .camera-name {
    display: none;
    white-space: nowrap;
    line-height: 14px;
    animation-duration: 0.25s;
    animation-name: slideup;
    background: rgba(18, 0, 45, 0.4);
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: 5px 9px;

    a {
      color: white;
      text-decoration: none;
    }

    .boardElement:hover & {
      display: block;
      align-items: center;
    }

    .link-quality {
      margin-left: 10px;
      margin: 0 0 0 8px;
      vertical-align: baseline;
    }
  }
}

.camera-name-icon {
  margin-top: -3px;
  margin-right: 6px;
}

.link-quality-icon {
  margin-top: -3px;
  margin-right: 6px;
}

.camera-audio-level {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40%;
  width: 100%;
}

@keyframes slideup {
  from {
    margin-top: 20px;
    opacity: 0;
  }

  to {
    margin-top: 0%;
    opacity: 1;
  }
}

.videoElement .animation {
  position: relative;
  display: flex;
  justify-content: center;
}

.active-camera-element .animation {
  width: 100%;
  height: 100%;
  animation-name: bounce-in;
  animation-duration: 0.65s;
  animation-fill-mode: both;
}

.animate-in {
  width: 100%;
  height: 100%;
  animation-name: bounce-in;
  animation-duration: 0.65s;
  animation-fill-mode: both;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.3);
  }
  50% {
    animation-timing-function: ease-in-out;
    transform: scale(1.05);
  }
  70% {
    animation-timing-function: ease-in-out;
    transform: scale(0.95);
  }
  100% {
    animation-timing-function: ease-in-out;
    transform: scale(1);
  }
}

.disappearing-camera-element .animation {
  animation-name: bounce-out;
  animation-duration: 0.65s;
  animation-fill-mode: both;
}

@keyframes bounce-out {
  0% {
    animation-timing-function: ease-in;
    transform: scale(1);
  }
  50% {
    animation-timing-function: ease-in-out;
    transform: scale(1.05);
  }
  100% {
    animation-timing-function: ease-in-out;
    transform: scale(0);
  }
}

.inactive-camera-element {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);

  .animation {
    width: 100%;
    height: 100%;
  }
}

.camera-circle {
  clip-path: circle(50%);
  -webkit-clip-path: circle(50%);
}

.camera-star {
  clip-path: polygon(
    100% 50%,
    91% 61%,
    93% 75%,
    80% 80%,
    75% 93%,
    61% 91%,
    50% 100%,
    39% 91%,
    25% 93%,
    20% 80%,
    7% 75%,
    9% 61%,
    0% 50%,
    9% 39%,
    7% 25%,
    20% 20%,
    25% 7%,
    39% 9%,
    50% 0%,
    61% 9%,
    75% 7%,
    80% 20%,
    93% 25%,
    91% 39%
  );
  -webkit-clip-path: polygon(
    100% 50%,
    91% 61%,
    93% 75%,
    80% 80%,
    75% 93%,
    61% 91%,
    50% 100%,
    39% 91%,
    25% 93%,
    20% 80%,
    7% 75%,
    9% 61%,
    0% 50%,
    9% 39%,
    7% 25%,
    20% 20%,
    25% 7%,
    39% 9%,
    50% 0%,
    61% 9%,
    75% 7%,
    80% 20%,
    93% 25%,
    91% 39%
  );
}

.camera-hexagon {
  clip-path: polygon(
    100% 50%,
    75% 93%,
    25% 93%,
    0% 50%,
    25% 7%,
    75% 7%,
    100% 50%,
    100% 0%,
    0% 0%,
    0% 100%,
    100% 100%,
    0% 100%,
    100% 0%,
    0% 0%,
    100% 0%,
    0% 100%,
    0% 0%,
    100% 0%
  );
  -webkit-clip-path: polygon(
    100% 50%,
    75% 93%,
    25% 93%,
    0% 50%,
    25% 7%,
    75% 7%,
    100% 50%,
    100% 0%,
    0% 0%,
    0% 100%,
    100% 100%,
    0% 100%,
    100% 0%,
    0% 0%,
    100% 0%,
    0% 100%,
    0% 0%,
    100% 0%
  );
}

.camera-rect {
  clip-path: inset(0% 0% 0% 0% round 10%);
  -webkit-clip-path: inset(0% 0% 0% 0% round 10%);
}

.camera-triangle {
  clip-path: polygon(0% 14%, 100% 14%, 50% 100%);
  -webkit-clip-path: polygon(0% 14%, 100% 14%, 50% 100%);
}

.camera-heart {
  clip-path: url(#camera-heart-path);
  -webkit-clip-path: url(#camera-heart-path);
}

.caption-container {
  display: none;
  position: absolute;
  min-width: 100%;
  height: 1.8em;
  color: #3aff16;
  font-family: Digital, sans-serif;
  letter-spacing: 0.5px;
  overflow: hidden;
}

.caption-container div {
  position: absolute;
  display: inline-block;
  margin: 0;
  padding: 0 7px;
  white-space: nowrap;
}
