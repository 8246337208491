.wayfinder {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 84px;
  height: 84px;
  overflow: visible;

  svg {
    width: 84px;
    height: 84px;
    transform-origin: 40px 52px;
  }

  // sound bar animation
  .bars {
    margin: -30px 0 0 -20px;
    position: sticky;
    margin: 30px 26px;
    margin-bottom: 6px;
    float: right;
  }

  .bar {
    background: white;
    bottom: 1px;
    height: 2px;
    position: absolute;
    width: 3px;
    animation: sound 0ms -600ms linear infinite alternate;
  }

  @keyframes sound {
    0% {
      opacity: 1;
      height: 3px;
    }
    100% {
      opacity: 1;
      height: 8px;
    }
  }

  .bar:nth-child(1) {
    left: 2px;
    animation-duration: 474ms;
  }
  .bar:nth-child(2) {
    left: 7px;
    animation-duration: 433ms;
  }
  .bar:nth-child(3) {
    left: 12px;
    animation-duration: 407ms;
  }
  .bar:nth-child(4) {
    left: 17px;
    animation-duration: 458ms;
  }
  .bar:nth-child(5) {
    left: 22px;
    animation-duration: 400ms;
  }
  .bar:nth-child(6) {
    left: 27px;
    animation-duration: 427ms;
  }
  .bar:nth-child(7) {
    left: 32px;
    animation-duration: 441ms;
  }
  .bar:nth-child(8) {
    left: 37px;
    animation-duration: 419ms;
  }
  .bar:nth-child(9) {
    left: 42px;
    animation-duration: 487ms;
  }
  .bar:nth-child(10) {
    left: 47px;
    animation-duration: 442ms;
  }

  // End sound bar CSS
}
