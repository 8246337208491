.uk-nav > li {
  a,
  button:not(.btn):not([class*='here-button-']) {
    padding: 0;
    line-height: 2.5em;
  }

  button:not(.btn):not([class*='here-button-']) {
    appearance: none;
    outline: none;
    border: none;
    background: transparent;
    color: #999;
    font-size: 14px;

    &.separated-option {
      border-top: 1px solid lightgray;
    }

    &:not([disabled]) {
      cursor: pointer;

      &:hover {
        color: #666;
      }
    }
  }
}

.uk-dropdown {
  padding: 10px 15px;
  border-radius: 10px;

  li {
    button.options-menu-option {
      line-height: 2.3em;
    }
  }
}

.uk-modal {
  z-index: 99999;
}

.uk-button {
  border-radius: 4px;
}

.danger-confirm .uk-modal-dialog .uk-button-primary,
.uk-button-danger {
  background-color: #f6335d;
}

.uk-nav-divider {
  border-top: 1px solid var(--primary-foreground, #e5e5e5) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}
