@import './variables.less';

#report-user-notice {
  z-index: 100000;
}

.report-user-modal {
  width: 45%;
  border-radius: 10px;
  overflow: auto;
  padding: 20px 20px 40px 20px;
  max-height: 100%;
  min-height: 350px;

  .report-user-header {
    text-align: center;
    font-weight: 500;
    margin: 10px 0px;
  }

  .report-user-subtitle {
    padding: 0;
    margin: 0;
    color: rgb(127, 127, 127);
    text-align: center;
  }
  .report-user-option-section {
    margin: auto;
    text-align: center;
    margin-top: 20px;
  }

  .report-user-textarea {
    height: 105px;
    width: calc(100% - 36px);
    margin: 10px 0px;
    border: 1px solid #dcdcdc;
    color: black !important;
    padding: 18px;
    font-size: 18px;
    line-height: 24px;
    resize: none;
    border-radius: 10px;

    &:focus {
      outline: none !important;
      border: 1px solid @primaryColor !important;
    }
  }

  #report-user-error {
    color: red;
    margin-bottom: 20px;
    margin-top: 0;
  }

  .report-user-confirm-button {
    width: 49%;
    color: white !important;
    background-color: @primaryColor;
    border-radius: 12px;
    line-height: 1.5em;
    min-height: 46px;
    padding: 0;
  }

  .report-user-back-button {
    min-height: 46px;
    border-radius: 12px;
    background-color: #f6f6f6;
    border: none;
    color: black;
    width: 49%;
    padding: 0;
  }

  .report-user-center {
    text-align: center;
  }

  .report-user-cancel-button {
    margin-top: 20px;
    border-radius: 12px;
    background-color: none;
    border: none;
    color: #01010180;
    text-decoration: underline;
    padding: 0;
    line-height: 1.5em;
    min-height: 46px;
  }

  .report-user-option {
    margin-right: 10px;
    min-height: 46px;
    border-radius: 12px;
    background-color: none;
    border: none;
    background-color: #ededed;
    color: black;
    margin: 4px 0px;
    line-height: 1.5em;

    &:hover {
      background-color: @primaryColor;
      color: white;
    }
  }

  .error-field {
    background-color: rgba(255, 0, 0, 0.15);
  }

  #report-user-option-success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .report-user-check-icon {
    height: 80px;
    width: auto;
  }

  #report-user-main-modal {
    margin-top: 16px;
  }
}
