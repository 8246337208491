.spaces-gifs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  margin-bottom: 10px;

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
  }
}
