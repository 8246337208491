@import '../../../styles/variables.less';
@import '../../../styles/mixins.less';

.invite-container {
  min-width: 370px;

  *.onboarding-hidden {
    display: none !important;
  }

  .invite-title {
    text-align: center;
    line-height: 28px;
    font-weight: 800;
    font-size: 14px;
    color: var(--primary-foreground, @primaryColor);
  }

  .invite-content {
    .invite-tab-no-friends-label-section {
      display: none;
      text-align: center;

      .better-with-friends-title {
        font-size: 24px;
        color: var(--secondary-background, @primaryColor);
        line-height: 26px;
        margin-bottom: 12px;
        font-weight: 800;
      }

      .better-with-friends-subtitle {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        font-weight: 500;
        color: var(--primary-foreground, @darkText);
      }
    }

    .invite-tab-link-section {
      margin-top: 12px;
      padding: 0 20px;

      .board-share-url {
        background: rgba(196, 196, 196, 0.3);
        border-radius: 8px;
        color: black;
        font-size: 0.875rem;
        padding: 11px 21px;
        line-height: 24px;
        width: 100%;
        box-sizing: border-box;
        border: none;

        &.url-invalid {
          border: 1px solid @error;
          outline: none;
        }

        &.url-valid {
          border: 1px solid @success;
          outline: none;
        }
      }

      .input-err {
        display: block;
        text-align: center;
        color: @error;
        font-size: 12px;
      }

      .copy-share-link,
      .update-share-link {
        width: 100%;
        box-sizing: border-box;
        font-size: 1rem;
        padding: 11px;
        line-height: 24px;
        margin-top: 8px;
        transition-duration: @transitionSpeed;

        &.highlight-default-pulse {
          &:hover {
            // don't make it transparent on hover when onboarding
            background: var(--secondary-background, @primaryColor);
          }
        }

        svg {
          margin-right: 8px;
        }
      }
    }

    .invite-text-comments {
      color: rgba(1, 1, 1, 0.6);
      font-size: 12px;
      height: 24px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      place-content: center;

      body.themed & {
        color: var(--primary-foreground);
      }

      a {
        text-decoration: underline;
        color: inherit;
        display: inline;
        margin-left: 4px;

        &:hover {
          color: var(--secondary-background, #666);
        }
      }

      .change-url-wrapper {
        margin-left: 4px;
      }
    }

    .invite-friends-section {
      display: none;
      background: rgba(0, 0, 0, 0.05);
      margin: 8px;
      border-radius: 8px;
      padding-top: 8px;

      .invite-friends-label {
        font-weight: 700;
        font-size: 12px;
        color: var(--primary-foreground, @primaryColor);
        opacity: 0.6;
        line-height: 24px;
        text-align: center;
      }
    }

    .external-resource-button {
      font-size: 13px;
      font-weight: bold;
      height: 46px;

      .discord-button-icon {
        width: 40px;
        margin-right: 7px;
      }
    }

    .share-on-discord-button {
      width: 90%;
      margin: 8px auto;
      background: @discord;
      color: white;
      position: relative;
      text-align: left;

      .forward-icon {
        width: 28px;
        opacity: 0.7;
        position: absolute;
        right: 6px;
        top: 7px;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 250px;
      }
    }

    .connect-to-discord {
      background: @discord;
      color: white;
      padding: 20px 17%;
      position: relative;

      .message {
        text-align: center;
        margin-bottom: 16px;
        font-weight: bold;
      }

      .connect-to-discord-button {
        width: 100%;
        background: white;
        color: black;
        justify-content: left;
        padding-left: 18px;

        .discord-button-icon {
          rect {
            fill: white;
          }

          path {
            fill: @discord;
          }
        }
      }

      .close {
        @size: 30px;

        width: @size;
        height: @size;
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.3);
        text-align: center;
        font-size: 18px;
      }
    }

    .integrations {
      display: none;
    }
  }
}

.onboarding-step {
  display: flex;
  flex-direction: column;
  align-items: center;

  .welcome-image {
    height: 135px;
  }

  .welcome-title {
    color: var(--primary-foreground, @primaryColor);
    font-family: Nunito, Inter, sans-serif;
    font-weight: bolder;
    font-size: 30px;
    text-align: center;
  }
}
