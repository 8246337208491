.chess-element {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  font-weight: normal;
  background: var(--primary-background, linear-gradient(#5818b7, #8b128f));
  color: var(--primary-foreground, white);

  h1 {
    color: var(--primary-foreground, white);
  }

  h2 {
    margin-top: 12px;
    font-weight: bold;
    font-size: 30%;
  }

  button:not(.options-menu-option) {
    height: 50px;
    padding: 20px;
    font-size: 1.2em;
    line-height: 5px;
    margin: 0 auto;
  }

  .chessboard {
    width: 100%;
    height: auto;
    margin: auto;
    text-align: -webkit-center;
  }

  .chessboard-frame {
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  .chess-status {
    text-align: center;
    padding: 10px;
    font-size: 1.3em;
  }

  .game-title-div {
    width: 50%;
    margin: auto;
    margin-top: 35%;
    background: var(--primary-background, black);
    border-radius: 18px;
    padding: 26px;
    border: 1px solid white;
    overflow: auto;
    text-align: center;
  }
}
