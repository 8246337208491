@import './codemirror.less';
@import './firepad.less';
@import './variables.less';
@import './mixins.less';
@import './fonts.less';
@import './notifications.less';
@import './first-time-user-notification.less';
@import './broadcast-leaving-dialog.less';
@import './uikit-overrides.less';
@import './radix-overrides.less';
@import './react-confirm-alert-overrides.less';
@import './upload-menu.less';
@import './theming.less';
@import './public-rooms.less';
@import './reports.less';
@import './screen-share-notice.less';
@import './range-input.less';
@import './user-profile.less';
@import './onboarding.less';

* {
  font-family: Inter, sans-serif;
  cursor: var(--current-cursor);
}

body {
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  user-select: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: unset !important; // fixing uikit issue

  #root {
    width: 100%;
    height: 100%;
  }

  &:not(.user-card) {
    min-width: 375px;
  }
}

iframe {
  pointer-events: all;
}

svg.clip-shape-definitions {
  position: absolute;
}

.homepage-here-logo,
.get-the-mobile-app-logo {
  position: fixed;
  display: none;
}

.homepage-here-logo {
  top: 0;
  left: 40px;
  width: 190px;
  z-index: 10000;
}

.get-the-mobile-app-logo {
  top: -130px;
  right: -80px;
  width: 350px;
  z-index: 10000;
}

.welcome-bar {
  position: absolute;
  bottom: 0;
  padding-top: 20px;
  height: 75px;
  width: 100%;
  display: none;
  text-align: center;
  background: url('/images/homepage-bottom-gradient.png') no-repeat bottom;
  background-size: 100% 550px;
  transition: height 0.5s ease-in-out;

  &.active {
    display: block;
    height: 150px;
  }

  .buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.active {
      opacity: 1;
    }
  }
}

.whitePurpleIcon(@colorToShow, @colorToHide) {
  &.@{colorToShow} {
    display: block;
  }

  &.@{colorToHide} {
    display: none;
  }
}

.signup-button {
  position: relative;
  height: 60px;
  width: 280px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #6b1be3;
  font-size: 18px;
  font-weight: bolder;
  line-height: 5px;
  border: 2px solid #6b1be3;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  pointer-events: all;

  .win-logo-icon {
    .whitePurpleIcon(purple, white);
  }

  .apple-logo-icon {
    .whitePurpleIcon(purple, white);
  }

  .arrow-icon {
    .whitePurpleIcon(purple, white);
  }

  &.hidden {
    display: none;
  }

  &:hover {
    color: white;
    background-color: #6b1be3;

    .win-logo-icon {
      .whitePurpleIcon( white, purple);
    }

    .apple-logo-icon {
      .whitePurpleIcon( white, purple);
    }

    .arrow-icon {
      .whitePurpleIcon( white, purple);
    }
  }

  &:active {
    box-shadow: 0;
    transform: translate(1px, 1px);
  }

  &.login-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    border-radius: 100px;

    &:hover {
      color: #6b1be3;
    }
  }

  &.main-signup {
    position: absolute;
    left: 0;
    top: -150px;
    right: 0;
    width: 240px;
    margin: auto;
    color: white;
    border: 2px solid white;
    background-color: #6b1be3;
    transform: scale(1.3);

    &:hover {
      color: #6b1be3;
      background-color: white;
    }
  }

  &.download-desktop-button,
  &.get-started {
    &::after {
      content: ' ';
      bottom: -5px;
      position: absolute;
      width: 97%;
      height: 5px;
      border-radius: 0 0 50px 50px;
      background-color: #6b1be3;
    }
  }
}

.sidebar-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 100%;
  z-index: 2; // higher than #main
  background-color: #ffffff55;
}

.leave-confirmation-button {
  margin-left: 50%;
  transform: translate(-50%, 0);
  text-transform: none;
}

.board-title-settings-button {
  text-decoration: none;
  margin: auto;

  display: flex;
  align-items: center;

  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: transform;
  transition-property: transform;
  height: 44px;

  &:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    text-decoration: none;

    #board-title {
      text-decoration: underline;
    }
  }
}

@media (max-width: 764px) {
  .board-title-settings-button {
    display: none;
  }
}

#board-title {
  font-size: 1.1em;
  padding: 0 8px;
  color: var(--primary-foreground, #eee);
  border-radius: 15px;
  vertical-align: text-bottom;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 268px;

  &.public-room-board-title {
    max-width: 228px;
    display: inline-block;
  }
}

.board-title-margin-left {
  margin-left: 8px;
}

#camera-on-button,
#camera-off-button,
#mic-on-button,
#mic-off-button,
#lens-menu-button,
.room-settings-button {
  border-radius: 25px;
  width: 36px !important;
  height: 36px !important;
  background-size: 20px !important;
  cursor: pointer;
}

.room-settings-button svg {
  width: 20px;

  .svg-color(var(--primary-foreground, white));
}

.inactive-button {
  background-color: @inactiveButton !important;
  cursor: initial !important;
  opacity: 0.5;
}

#room-settings-button {
  width: 16px;
  height: 16px;
  margin-bottom: 3px;
}

#room-settings {
  max-width: 800px !important;
}

.permission-tooltip {
  width: 160px !important;
  text-align: center;
  font-weight: 600;
}

.quick-jump-title {
  position: absolute;
  bottom: 17px;
  line-height: 0.85em;
  color: white;
  text-overflow: ellipsis;
}

#board-link {
  width: 10em;
  overflow-x: scroll;
}

a.board-link {
  display: inline;
}

#dropdown-board-select {
  padding-right: 5px;
}

.board-options {
  display: none;
  background-image: url(/images/icons/options-vertical.png);
  filter: invert(0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;
  width: 30px;
  height: 30px;
}

.board-list-item {
  line-height: 2em;
}

.board-list-item:hover .board-options {
  display: inline-block;
}

#dropdown-board-list {
  max-height: 30em;
  overflow: scroll;
}

.board-background {
  position: relative;
}

.header {
  display: none;
  position: absolute;
  padding: 5px;

  z-index: 10000;
  background-color: clear;
  color: white;
}

.room-menu-container {
  position: absolute;
  bottom: 10px;
  width: calc(100% - @sidebarWidth);
  margin-left: @sidebarWidth;
  z-index: 10005; // this z-index should be higher that z-index of the #global-menu-container
  display: flex;
  justify-content: space-between;
}

.element-feed {
  position: relative;
  margin: 0;
}

.room-menu-bar-toggler {
  margin: 0 10px;
  padding: 10px;
  height: fit-content;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #12002d;
  border-radius: 50%;
}

@media (min-width: 1101px) {
  .room-menu-container {
    @feed-element-size: 359px;

    .invisible-feed-element {
      margin-right: auto;
      width: @feed-element-size;
      visibility: hidden;
    }

    .element-feed {
      margin-left: auto;
      width: @feed-element-size;
    }
  }
}

@media (max-width: 1100px) {
  .element-feed {
    flex-grow: 1;
    max-width: 359px;
  }
}

@media (max-width: 1235px) and (min-width: 891px) {
  .room-menu-container {
    display: flex;
    justify-content: space-between;

    .invisible-feed-element {
      display: none;
    }
  }
}

@media (max-width: 613px) {
  .room-menu-container {
    margin-left: 0;
    width: 100%;
    justify-content: space-between;

    .invisible-feed-element {
      position: absolute;
    }

    .element-feed {
      flex-grow: 1;
    }
  }
}

.electron-grab-area {
  -webkit-app-region: drag;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 100%;
  height: 50px;
}

#electron-toolbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  z-index: -1;
  display: none;
}

#global-menu-container {
  text-align: center;
  position: absolute;
  top: 10px;
  padding: 0 10px;
  width: 100%;
  z-index: 10011;
  pointer-events: none;
  display: none;
  justify-content: center;
  align-items: center;

  &.electron-windows {
    top: 25px;
  }
}

.quick-room-switcher-root {
  position: absolute;
  left: 10px;
  height: 46px;
  pointer-events: all;
  -webkit-app-region: none;
}

.element-menu-bar {
  z-index: 10000;
  position: absolute;
  bottom: 5px;
  left: 5px;
  display: none;

  &.hidden {
    display: none !important;
  }
}

.element-container:hover .element-menu-bar {
  display: inline-flex;
}

#room-menu-bar,
#global-menu-bar {
  display: inline-flex;
  -webkit-app-region: none;
}

#room-menu-bar,
#global-menu-bar,
.element-menu-bar {
  background: var(--primary-background, @menuBackground);
  color: var(--primary-foreground, white);
  pointer-events: all;
  align-items: center;
  text-align: left;
  height: @topMenuHeight;
  line-height: @topMenuHeight;
  border-radius: 30px;
  padding: 0 12px;
  box-shadow: 0 0 6px @menuShadowColor;
}

@media (max-width: 990px) {
  #global-menu-container {
    justify-content: flex-start;
  }

  .quick-room-switcher-root {
    position: static;

    &.hidden {
      display: none;
    }
  }

  #global-menu-bar {
    padding: 0 5px 0 0;
    border-radius: 0 30px 30px 0;
    box-shadow: none;
  }

  .room-settings-button {
    padding: 0;
    border-radius: 0 25px 25px 0;

    // reset default scale .menu-button on hover
    &:hover {
      transform: scale(1);
    }
  }
}

#room-menu-bar {
  margin: 0 10px;
}

#elements {
  transform: translate(0, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;

  /*
 #elements::before
  width: 1000%;
  height: 1000%;
  background-size: 1000%;
  background-repeat: repeat;
  background-image: url('https://images.unsplash.com/photo-1517263904808-5dc91e3e7044?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNzY2NTR8MHwxfHNlYXJjaHwzfHxkaXNjb3xlbnwwfHx8&ixlib=rb-1.2.1&q=80&w=1080');
 */
}

.camera-active-left {
  border-radius: 15px 0 0 15px;
  width: 45px;
  background-size: 40px 40px;
}

.camera-active-right {
  border-radius: 0 15px 15px 0;
  width: 25px;
  background-size: 12px 12px;
}

.camera-active-left,
.camera-active-right {
  outline: none;
  height: 28px;
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
}
.camera-active-left:hover,
.camera-active-right:hover {
  height: 28px;
}

.device-select {
  text-align: left;
  line-height: 36px;
  margin-bottom: 4px;
  margin-top: 4px;
}

.lenses-popover-link {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 32px;
  color: var(--secondary-background, #6b1be3);

  .svg-color(var(--secondary-background, #6b1be3));
}

#device-settings {
  padding: 30px;
  background: var(--primary-background, white);

  label {
    color: var(--primary-foreground);
    border: none;
  }

  select {
    width: 100%;
  }

  .camera-mic-problems-help-link-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.85em;

    a {
      color: var(--secondary-background, @primaryColor);
    }
  }
}

.device-select img {
  position: absolute;
  height: 20px;
  width: 20px;
  margin-top: 8px;
}

.device-options option:checked {
  background: #333;
  padding: 20px;
  width: 100px;
  border: 3px solid #f00;
}

.camera-settings-divider {
  padding-right: 7px;
}

.menu-divider {
  height: 20px;
  margin: 0 10px;
  border-color: var(--primary-foreground, #606060);
}

.menu-button-large-icon {
  background-size: 20px 20px;
}

#color-select {
  line-height: 1em;
}

.waitlist-button-wrapper {
  display: inline-flex;
  height: @topMenuHeight;
  width: 67px;
  border-radius: @topMenuHeight / 2;
  background: var(--primary-background, @menuBackground);
  font-size: 12px;
  align-items: stretch;
  justify-content: center;
  margin-right: 8px;
  pointer-events: all;
  padding: 5px 6px;
  box-sizing: border-box;

  #waitlist-button {
    cursor: pointer;
    background: none;
    border-radius: 30px;
    flex-grow: 1;
    line-height: 32px;
    color: var(--primary-foreground, white);
    .svg-color(currentcolor);

    &:hover,
    &.active-waitlist {
      background: var(--secondary-background, @primaryColor);
      color: var(--secondary-foreground, white);
    }
  }

  & span {
    margin-left: 7px;
  }
}

#error-message {
  position: absolute;
  width: 500px;
  margin-left: -250px;
  left: 50%;
  top: 60px;
  z-index: 10000;
  display: none;
  max-height: 300px;
  color: #fff;
  text-shadow: 1px 1px #333;
  overflow-y: scroll;
}

#room-messages {
  position: absolute;
  width: 624px;
  top: 60px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10000;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #594d6c;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    height: 64px;
  }
}

@media (max-width: 625px) {
  #room-messages {
    max-width: 624px;
    width: 90%;
  }
}

#room-message-list {
  margin: auto;
  padding-top: 10px;
  width: fit-content;
}

#room-message-close {
  height: 30px;
  width: 30px;
  color: #ddd;
  background: #00000000;
  border: none;
  cursor: pointer;
  float: right;
  outline: none;
}

#room-message-close:hover {
  color: #444;
}

.room-message {
  margin: 10px;
  padding-right: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: 72px;
  display: flex;
  align-items: center;
  margin-top: 0;
}

@media (max-width: 625px) {
  .room-message {
    height: 95px;
  }
}

.waitlist-message-icon {
  width: 84px;
  background: #6b1be3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  border-radius: 10px 0 0 10px;
  flex-shrink: 0;

  &.message-input-active {
    margin-right: 5px;
  }
}

.waitlist-message-content-grid {
  display: grid;
  grid-template-areas: 'waitlist-message-text-wrapper room-message-buttons waitlist-delete-button';

  .waitlist-message-text-content {
    max-width: 300px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 625px) {
  .waitlist-message-content-grid {
    grid-template-areas: 'waitlist-message-text-wrapper waitlist-delete-button' 'room-message-buttons .';
  }
}

.waitlist-message-text-wrapper {
  grid-area: waitlist-message-text-wrapper;
  align-self: center;
}

.room-message-buttons {
  grid-area: room-message-buttons;
  display: flex;
}

@media (max-width: 625px) {
  .room-message-buttons {
    justify-self: start;
  }
}

.waitlist-delete-button {
  width: 16px;
  grid-area: waitlist-delete-button;
  align-self: center;
  justify-self: center;
}

.waitlist-message-text {
  color: #101010;
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
}

.waitlist-allow-button {
  background: #6b1be3;
  border-radius: 5px;
  border: none;
  height: 36px;
  width: 108px;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}

.waitlist-message-button,
.waitlist-delete-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.room-message-input {
  width: 100%;
  height: 34px;
  outline: none;
  padding: 0 10px;
  box-sizing: border-box;
  border: 2px solid #6b1be3;
  border-radius: 5px;
  font-size: 14px;
  color: #000;
  padding-right: 30px;
}

.room-message-buttons {
  flex-shrink: 0;
  align-self: center;
}

.room-send-message-input-wrapper {
  position: relative;
}

.msg-sent {
  color: #6b1be3;
  font-weight: 600;
  font-size: 10px;
}

.room-send-message-input-button {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 6px;
  right: 6px;
  cursor: pointer;
}

.room-send-message {
  margin-right: 20px;
  flex-shrink: 0;
  text-align: center;
  flex-grow: 1;
}

.room-send-message-delete {
  margin-left: auto;
  cursor: pointer;
  font-size: 10px;
  line-height: 24px;
  color: #b8b8b8;
}

.room-send-message-title {
  display: flex;
  align-items: baseline;
}

.room-message-button {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 15px;
  line-height: 20px;
  background-color: #334488;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}

.room-message-button:hover {
  background-color: #56a;
}

.room-send-message-text {
  text-align: left;
  font-weight: 600;
  font-size: 10px;
  color: #101010;
}

.video-card {
  /*backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px); */
  background-color: rgba(1, 1, 1, 0.4);
  color: white;
  padding: 20px;
  border-radius: 20px;
}

.imageCard .element-container img {
  position: absolute;
  max-width: none;
  max-height: none;
  width: 100%;
  height: 100%;
}

.image-embedded-video {
  width: 100%;
  height: 100%;
}

.fileCard img {
  position: absolute;
  max-width: none;
  max-height: none;
  width: 100%;
  height: 100%;
}

.subtitle {
  font-size: 1.2em;
  padding-bottom: 5px;
}

#file-upload-form {
  display: none;
}

#file-upload-status {
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: @primaryColor;
  border-style: solid;
  border-color: white;
  border-width: 2px;
  text-align: center;
  font-size: 1em;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  color: white;
  bottom: 70px;
  min-width: 500px;
  opacity: 85%;

  img {
    height: 19px;
    margin-right: 5px;
  }
}

#booth-countdown {
  display: none;
  position: absolute;
  height: 60px;
  color: white;
  text-shadow: 1px 1px #333333;
  bottom: 60px;
  width: 100%;
}

.booth-timer {
  position: absolute;
  border-style: dotted;
  border-color: yellow;
  border-width: 2px;
  text-align: center;
  font-size: 2em;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  left: 50%;
  margin-left: -25px;
}

.booth-message {
  text-align: center;
  width: 100%;
  top: -30px;
  position: absolute;
}

.mic-buttons,
.camera-buttons,
.screenshare-buttons,
.new-element-buttons {
  position: relative;
  display: inherit;
}

#file-upload-dropdown-form {
  margin-top: 10px;
}

#screenshare-off-button {
  z-index: 1;
}

#mic-levels {
  position: absolute;
  border-radius: 25px;
  height: 100%;
  width: 70px;
}

#lens-menu-button {
  display: none;
  margin-left: 5px;
  background-color: #f6335d;

  .svg-color(white);

  &.camera-enabled {
    display: block;
  }

  &:not(.lens-enabled) .enabled-icon {
    display: none;
  }

  &.lens-enabled {
    background-color: #50c832;

    .disabled-icon {
      display: none;
    }
  }
}

.camera-on-color,
.lens-color {
  background-color: rgb(80, 200, 50);
}

.mic-on-bg-color {
  background-color: rgb(80, 100, 25);
}

.camera-off-color {
  background-color: @offColor;
}

.mic-buttons {
  margin-left: 5px;
}

.device-options-button {
  z-index: 2;
  border: 0;
  margin-left: 6px;
  cursor: pointer;
  background-color: transparent;

  svg {
    width: 12px;

    .svg-color(var(--primary-foreground, white));
  }
}

textarea {
  font-family: Arial;
}

textarea.textEdit {
  width: 100%;
  height: 95%;
  resize: none;
  background-color: #333;
  color: #ddd;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.background-container {
  overflow: scroll;
  max-height: 25em;
  width: 300px;
}

.presence-icon {
  position: relative;
  text-align: center;
  clip-path: circle(50%);
  width: @topMenuHeight - 10px;
  height: @topMenuHeight - 10px;
  color: white;

  &.wayfinder-icon {
    position: absolute;
    top: 34px;
    left: 22px;
  }
}

.more-icon {
  border: 3px solid var(--primary-background, @menuBackground);
  border-radius: 50%;
  background: var(--primary-foreground, white); // Inverted colors
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 7px;
  margin-left: -10px;
  z-index: 1;
  cursor: pointer;
  position: relative;
  margin-right: -3px;
  transition-duration: 0.3s;

  &:hover {
    background-color: #bebebe;
  }

  .user-count {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    height: 100%;
    width: 100%;
    color: var(--primary-background, @menuBackground);
  }

  .user-count-tooltip {
    background: var(--primary-background, white);
    position: absolute;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    height: 45px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
    transition-duration: 0.3s;
    top: 33px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    box-sizing: border-box;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      top: -7px;
      z-index: -1;
      transform: translateX(-50%) rotateZ(45deg);
      left: 50%;
    }

    .user-count-wrapper {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      box-sizing: border-box;
      min-width: 30px;
      color: var(--primary-foreground, black);
    }
  }

  .user-count:hover + .user-count-tooltip {
    visibility: visible;
    opacity: 100%;
    transform: translateY(0) translateX(-50%);
  }

  .user-count-icon {
    margin-right: 4px;

    .svg-color(var(--primary-foreground, black));
  }
}

.mute-button {
  width: 30px;
  height: 30px;
  display: none;
  background-image: url(/images/icons/mic-on.png);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  bottom: 0;
  position: absolute;
  border-radius: 50%;
  margin-left: -15px;
}

.mic-on {
  background-image: url(/images/icons/mic-on.png);
  background-color: rgba(255, 255, 255, 0.5);
}

.mic-off {
  background-image: url(/images/icons/mic-off.png);
  background-color: rgba(255, 64, 0, 0.5);
}

.mute-button:focus {
  outline: none;
}

#trashcan {
  background-color: rgba(255, 255, 255, 0.4);
  background-size: 32px 32px;
  clip-path: circle(40%);
  -webkit-clip-path: circle(40%);
  color: white;
  position: fixed;
  bottom: 50px;
  height: 100px;
  width: 100px;
  left: 50%;
  margin-left: -50px;
  background-image: url(/images/icons/trashcan.png);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 9999;
  display: none;
}

#main {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgb(15, 2, 43);
}

.tile-background {
  background-repeat: repeat;
}

.no-tile-background {
  background-repeat: no-repeat;
}

#pen-canvas {
  z-index: 9999;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: relative;
}

.color-indicator {
  border-radius: 50%;
  border: 2px solid var(--primary-foreground, white);
  background-color: #bd14ff;
  width: 15px;
  height: 15px;
  position: relative;
  margin: 0 auto;
}

#current-color {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  mask-image: url(/images/icons/pen-white.png);
  mask-size: 24px 24px;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url(/images/icons/pen-white.png);
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 24px 24px;
}

.pen-down {
  cursor: url(/images/icons/pen-cursor.svg) 11 27, crosshair;
}

.fadeout {
  opacity: 0;
  transition: opacity 500ms linear;
}

.user-cursor {
  position: absolute;
  z-index: 9997;
  pointer-events: none;
  margin-top: -8px;
  margin-left: -9px;

  .user-cursor-name {
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    color: #121212;
    font-family: 600;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.01em;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 16%);
    border-radius: 4px;
    height: 16px;
    line-height: 16px;
    padding: 0 4px;
    white-space: nowrap;
  }
}

.text-container ul ul {
  margin-top: 2px;
  margin-bottom: 2px;
}

/* Spawn points */

.spawn-camera {
  border: 2px yellow dashed;
  border-radius: 50%;
  width: 90%;
  height: 90%;
  margin: 5%;
}

.spawn-media {
  border: 2px yellow dashed;
  border-radius: 10px;
  width: 90%;
  height: 90%;
  margin: 5%;
}

.firepad-toolbar {
  white-space: nowrap;
}

.locked-toolbar-button-tooltip {
  display: flex;

  img {
    margin-right: 5px;
  }
}

.room-error {
  padding: 10px 0;
}

#webrtc-stats-box {
  display: none;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  top: 190px;
  right: 5px;
  width: 300px;
  height: 450px;
  overflow: scroll;
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  z-index: 10000;

  .webrtc-stat {
    padding: 10px 5px;
  }

  canvas {
    width: 300px;
    height: 200px;
  }
}

.uk-button {
  text-transform: unset;
}

.uk-tooltip {
  z-index: 9999999 !important;
  background-color: #12002d;
  padding: 10px 16px;
  border-radius: 6px;
  opacity: 0.95;
  word-wrap: break-word;
}

#room-kit-select {
  height: 550px !important;
  width: 700px !important;
  left: -90px !important;
}

#render-template-select {
  position: absolute;
  z-index: 1;
}

#render-item-select-underlay {
  display: none;
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0, 0.1);
  position: absolute;
}

#render-template-select {
  position: absolute;
  z-index: 1;
}

#render-template-select-underlay {
  display: none;
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0, 0.1);
  position: absolute;
}

#template-screen {
  &.hidden {
    display: none;
  }
}

.select-font-size-button {
  background: var(--primary-background, #eaeaea);
  color: var(--primary-foreground, black);
}

#board-title-public-icon {
  display: none;
  height: 40px;
  width: auto;
}

.error-field {
  background-color: rgba(255, 0, 0, 0.15);
}

#render-room-kit-select {
  z-index: 10006; // this z-index should be higher that z-index of the .room-menu-container (toolbar)
}

#dm-window {
  width: 280px;
  height: 400px;
  position: absolute;
  left: 50px;
  z-index: 2;
  pointer-events: none;
}

#sidebar-root {
  // we don't display it until room has loaded:
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10010; // above the toolbar
  height: 100vh;
  padding: 10px;
}

// Snapchat library legal popup
#tos-popup {
  position: absolute;
  z-index: 99999;
}
