.svg-color(@color) {
  path,
  circle,
  g,
  rect {
    &[fill]:not([fill='none']) {
      fill: @color;
    }

    &[stroke] {
      stroke: @color;
    }
  }
}

.custom-scrollbar(@color, @width) {
  &::-webkit-scrollbar {
    width: @width;
  }

  &::-webkit-scrollbar-track {
    background-color: @color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: @color;
  }
}
