@import './variables.less';

// dm window styles
.dm-window-container {
  .text-channel-container {
    background-color: unset;

    .sticker-select-text-channel {
      min-height: unset;
    }
  }

  .feed-list-scroll-wrapper {
    height: 326px;
    box-sizing: border-box;
    border-radius: 0;
    overflow: hidden auto;
  }

  .message-input-container {
    border-radius: 0 0 10px 10px;
  }

  .image-message-bubble {
    img {
      max-width: 225px;
    }
  }

  .chat-item-context-buttons {
    right: 2px;
  }

  .feed-image-message {
    .chat-item-context-buttons {
      right: 9px;
    }
  }
}
