#group-not-allowed-dialog {
    text-align: center;
  
    .title {
      font-size: 18px;
      margin: 8px 0 12px;
      place-content: center;
    }
  
    .title-image {
      margin-bottom: 20px;
  
      img {
        height: 400px;
      }
    }
  }
  