@import '../../../styles/variables.less';

here-avatar {
  height: 100%;
  width: 100%;

  .avatar {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .processing,
    .initial,
    .image {
      display: none;
    }

    .processing {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

    .image {
      height: 100%;
      width: 100%;
      object-fit: cover;

      &[src$='/anonymous.svg'] {
        padding: 15%;
      }
    }
  }
}

here-avatar.color-ring .image {
  border-radius: 50%;
  border: 2px solid transparent;
}

.bar-item here-avatar.color-ring > .avatar {
  border: 3px solid var(--primary-background, @menuBackground);
}
