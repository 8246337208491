@import './variables.less';
@import './theming.less';

.publicize-room-modal {
  width: 55%;
  border-radius: 10px;
  overflow: visible;
  padding: 20px 20px 40px 20px;
  min-width: 500px;
  max-width: 900px !important;
  background: var(--primary-background, white);

  .publicize-room-inner-modal {
    overflow: auto;
  }

  a {
    color: @primaryColor;
  }

  button {
    font-size: 16px;
  }

  .publicize-share-room-details-inner {
    width: 85%;
    height: calc(100% - 50px);
    margin: auto;
  }

  .publicize-room-switch-section {
    justify-content: space-between;
    display: flex;
  }

  .publicize-header {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin: 14px 0 4px;
    color: var(--primary-foreground, black);
  }

  .publicize-header-ended {
    margin: 30px 0 20px;
  }

  .publicize-header-subtitle {
    padding: 0;
    margin: 0;
    color: var(--primary-foreground, #7f7f7f);
  }

  .publicize-header-main-subtitle {
    padding: 0;
    margin: 0;
    color: var(--primary-foreground, #1a1a1a80);
    text-align: center;
    font-size: 14px;
  }

  .publicize-header-subtitle-notice {
    width: 50%;
    text-align: center;
    margin: auto;
    padding: 10px;
    color: var(--primary-foreground, black);
  }

  .publicize-header-subtitle-ended {
    width: 80%;
    text-align: center;
    margin: auto;
    padding: 10px;
  }

  .publicize-room-confirm-button-ended-div {
    margin: auto;
    margin-top: 30px;
    width: 70%;
  }

  .publicize-notice-section {
    margin: auto;
    margin-top: 14px;
    text-align: center;
  }

  .buttons-section {
    display: flex;
    justify-content: center;
  }

  .publicize-inner-header {
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    font-size: 14px;
    color: var(--primary-foreground, black);
  }

  #room-name-input-publicize {
    height: 16px;

    &:not(:focus) {
      border-width: 1px;
    }
  }

  .room-name-section {
    margin-bottom: 20px;
  }

  .publicize-content-div {
    background: #f6f6f6;
    padding: 28px;
    border-radius: 5px;
    margin-bottom: 10px;

    body.themed & {
      background: var(--primary-foreground-alpha-20);
    }
  }

  .publicize-textarea {
    height: 70px;
    width: calc(100% - 36px);
    padding: 14px;
    font-size: 14px;
    line-height: 20px;
    resize: none;
  }

  .publicize-textarea::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  .publicize-room-confirm-button {
    width: 41.5%;
    height: 46px;
  }

  .publicize-room-cancel-button {
    width: 41.5%;
    margin-right: 10px;
    height: 46px;
  }

  .publicize-room-gif {
    padding: 20px;
    object-fit: cover;
    max-height: 435px;
    display: block;
    margin: 0 auto;
  }

  .publicize-room-image-left-top {
    position: fixed;
    left: -95px;
    top: 50px;
    width: 120px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .publicize-room-image-left-bottom {
    position: fixed;
    left: -100px;
    bottom: 100px;
    width: 120px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .publicize-room-image-right {
    position: fixed;
    right: -130px;
    top: 230px;
    width: 150px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .publicize-share-details {
    overflow: hidden !important;
  }

  .required-message {
    color: @menuBackgroundHover;
    text-transform: lowercase;
    font-weight: normal;
    float: right;
    font-size: 14px;
  }

  .uk-input {
    &:focus {
      background-color: none !important;
    }
  }

  .flex-container {
    display: flex;
    text-align-last: center;
    justify-content: space-between;
    margin: 40px 0 14px 0;
    max-width: 1200px;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    height: 86px;
  }

  .gray-section {
    .themed-shadow;

    body.themed & {
      background: var(--primary-background);
      border: 1px solid var(--primary-foreground-alpha-20);

      &:hover {
        border: 1px solid var(--primary-foreground-alpha-80);
      }
    }
  }

  .flex-child {
    flex: 0.23;
    color: rgb(155, 143, 143);

    .gray-section {
      height: 100%;
      border-radius: 5px;
      min-width: 50px;
      background: rgba(196, 196, 196, 0.15);
      position: relative;
    }

    .option-section-image {
      margin-top: -22px;
      position: absolute;
      width: 100%;
    }

    .option-section-image-slash {
      margin-top: -22px;
      position: absolute;
      z-index: 1;
      height: 40px;
      content: url(../images/icons/slash.svg);
      display: none;
      width: 100%;
    }

    .permission-section {
      display: inline-block;
      transform: translate(0, 45%);

      h6 {
        margin-bottom: 6px;
        color: var(--primary-foreground, gray);
        font-weight: 600;
      }

      .activated {
        color: var(--primary-foreground, @menuBackgroundHover);
      }
    }
  }

  .error {
    display: block;
    text-align: left;
    color: red;
    font-size: 14px;
    margin: auto;
  }
}

.publicize-room-modal-master {
  border-radius: 10px;
  min-width: 600px;
}

.publicize-room-modal-details {
  width: unset !important;
  padding: 20px 20px 40px 20px;
  max-height: 100%;
  min-width: 500px !important;
  max-width: unset !important;
}

.invite-text-icon {
  height: 18px;
  margin-left: 4px;

  .svg-color(var(--primary-foreground, #bfbfbf));
}

#publicize-share-room-notice,
#publicize-share-room-details,
#publicize-share-room-ended {
  z-index: 100000;
}
